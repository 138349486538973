import { ModuleWithProviders, NgModule } from '@angular/core';

import { RouterModule } from '@angular/router';

import { NbLayoutModule, NbTabsetModule, NbThemeModule } from '@nebular/theme';
import { NgSelectModule } from '@ng-select/ng-select';

import { LayoutComponent } from '@app/theme/layout/layout.component';
import { EditorComponent } from '@app/theme/components/editor/editor.component';
import { ImageUploadModalComponent } from '@app/theme/components/image-upload-modal/image-upload-modal.component';
import { HeaderComponent } from '@app/theme/components/header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '@app/theme/components/footer/footer.component';
import { ColorPickerComponent } from '@app/theme/components/color-picker/color-picker.component';
import { LogoPreviewComponent } from '@app/theme/components/logo-preview/logo-preview.component';
import { LogoUsageHintsComponent } from '@app/theme/components/logo-usage-hints/logo-usage-hints.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

const BASE_MODULES = [
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  CommonModule,
];

const NGB_MODULES = [NgbDropdownModule];

const NB_MODULES = [NbLayoutModule, NbTabsetModule];

const LIB_MODULES = [NgSelectModule];

const COMPONENTS = [
  LayoutComponent,
  EditorComponent,
  ImageUploadModalComponent,
  HeaderComponent,
  FooterComponent,
  ColorPickerComponent,
  LogoPreviewComponent,
  LogoUsageHintsComponent,
];

const NB_THEME_PROVIDERS = [
  ...(NbThemeModule.forRoot({ name: 'default' }).providers || []),
];

@NgModule({
  imports: [...BASE_MODULES, ...NGB_MODULES, ...NB_MODULES, ...LIB_MODULES],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [...NB_THEME_PROVIDERS],
    };
  }
}
