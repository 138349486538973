import { AbstractControl, ValidationErrors } from '@angular/forms';

export function oneCheckboxChecked(
  control: AbstractControl
): ValidationErrors | null {
  let oneCheckboxChecked =
    false ||
    control.get('jpg')?.value ||
    control.get('png')?.value ||
    control.get('eps')?.value ||
    control.get('pdf')?.value ||
    control.get('svg')?.value;
  return oneCheckboxChecked ? null : { checkBoxGroup: { value: true } };
}
