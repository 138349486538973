import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-logo-usage-hints',
  templateUrl: './logo-usage-hints.component.html',
  styleUrls: ['./logo-usage-hints.component.scss'],
})
export class LogoUsageHintsComponent {
  @Input('backgroundColor') backgroundColor: string = 'white';
}
