import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { imageData } from '@app/lib/model/imageData';

@Injectable({
  providedIn: 'root',
})
export class CanvasExportService {
  canvas: fabric.Canvas;
  canvasWidth: number = 0;
  changedObjects: fabric.Object[] = [];

  objectNameBlocklist: string[] = [
    'uploadBox',
    'deleteGroup',
    'R-0',
    'R-1',
    'R-2',
    'P-0',
    'P-1',
    'P-2',
    'T-0',
    'T-1',
    'T-2',
  ];

  colors = {
    taintedBlue: '#507289',
    mintGreen: '#77B6BA',
    darkBlue: '#042C58',
    lightBlue: '#6AB2E7',
    petrolGreen: '#006B6B',
    green: '#26D07C',
    purple: '#4C3575',
    pink: '#D13896',
    red: '#E31B4C',
    orange: '#FFA252',
    black: '#000000',
    white: '#FFFFFF',
  };

  constructor(canvas: fabric.Canvas) {
    this.canvas = canvas;
  }

  public prepareCanvasForExport(
    objects: fabric.Object[] = this.canvas.getObjects()
  ): void {
    this.canvasWidth = this.canvas.getWidth();
    for (let object of objects) {
      if (this.typeGuardGroup(object)) {
        this.prepareCanvasForExport(object.getObjects());
      }
      if (object.get('excludeFromExport') && object.get('opacity') === 1) {
        object.set({ opacity: 0 });
        this.changedObjects.push(object);
      }
    }
  }

  public restoreCanvasAfterExport(): void {
    for (let object of this.changedObjects) {
      object.set({ opacity: 1 });
    }

    this.canvas.setWidth(this.canvasWidth);

    // revert back to normal
    // let newHeight = this.canvas!.getHeight() - 2;
    // this.canvas!.setHeight(newHeight);

    this.canvas.renderAll();
  }

  public getVisibleCanvasObjects(
    objects: fabric.Object[] = this.canvas.getObjects()
  ): fabric.Object[] {
    return objects.filter(
      (object: fabric.Object) =>
        !this.objectNameBlocklist.includes(object.get('name') ?? '')
    );
  }

  public generateCanvasImageData(
    fileFormats: AbstractControl,
    colorSpace: AbstractControl,
    maxWidth: number,
    borderWidth: number,
    textColor?: string,
    customTextLines?: number
  ): imageData {
    this.canvas.setHeight(this.canvas.getHeight() + 1);
    const imageData: imageData = {
      type: [],
      colorSpace: colorSpace.value,
      textColor: textColor,
      customTextLines: customTextLines ? customTextLines + 1 : customTextLines,
    };
    this.calculateCiBorder(borderWidth, maxWidth);

    const currentDimensions = {
      height: this.canvas.getHeight(),
      width: this.canvas.getWidth(),
    };

    if (imageData.colorSpace === 'digital') {
      const scaleRatio = 340 / currentDimensions.height;
      this.canvas.setDimensions({
        width: currentDimensions.width * scaleRatio,
        height: currentDimensions.height * scaleRatio,
      });
      this.canvas.setZoom(scaleRatio);
    }

    imageData.svgString = encodeURIComponent(
      this.canvas.toSVG({}, (param: string): string | void => {
        let xml = document.createElement('div');
        xml.innerHTML = param;
        let groups = xml.querySelectorAll('g');

        let elementVisible = true;
        for (let i = 0; i < groups.length; i++) {
          if (groups[i].outerHTML.includes('opacity: 0')) {
            elementVisible = false;
            break;
          }
        }

        if (elementVisible) {
          return param;
        }
      })
    );
    if (fileFormats.get('eps')?.value || fileFormats.get('svg')?.value) {
      // this always gets executed now because the backend always requires a svgstring
      //imageData.svgString = encodeURIComponent(this.canvas.toSVG());
      if (fileFormats.get('eps')?.value) {
        imageData.type.push('EPS');
      }
      if (fileFormats.get('svg')?.value) {
        imageData.type.push('SVG');
      }
    }

    if (imageData.colorSpace === 'print') {
      const scaleRatio = ((5 / 2.54) * 300) / currentDimensions.height;
      this.canvas.setDimensions({
        width: currentDimensions.width * scaleRatio,
        height: currentDimensions.height * scaleRatio,
      });
      this.canvas.setZoom(scaleRatio);
    }

    if (fileFormats.get('jpg')?.value) {
      imageData.jpegBase64Image = this.canvas.toDataURL({
        format: 'jpeg',
      });
      imageData.type.push('JPEG');
    }

    this.canvas.setZoom(1);
    this.canvas.setDimensions({
      width: currentDimensions.width,
      height: currentDimensions.height,
    });

    if (fileFormats.get('png')?.value) {
      imageData.pngBase64Image = this.canvas.toDataURL({
        format: 'png',
        multiplier: 2,
      });
      imageData.type.push('PNG');
    }

    if (fileFormats.get('pdf')?.value) {
      if (!imageData.type.includes('PNG') && !imageData.type.includes('JPEG')) {
        imageData.pngBase64Image = this.canvas.toDataURL({
          format: 'png',
        });
      }
      imageData.type.push('PDF');
    }

    this.canvas.setHeight(this.canvas.getHeight() + 1);

    return imageData;
  }

  public changeCanvasColors(
    objects: fabric.Object[],
    textColor:
      | 'taintedBlue'
      | 'mintGreen'
      | 'darkBlue'
      | 'lightBlue'
      | 'petrolGreen'
      | 'green'
      | 'purple'
      | 'pink'
      | 'red'
      | 'orange'
      | 'black'
      | 'white',
    backgroundColor: 'black' | 'white'
  ): void {
    this.canvas!.setBackgroundColor(this.colors[backgroundColor], () => {
      this.canvas!.renderAll();
    });
    for (let object of objects) {
      if (this.typeGuardGroup(object) && object.get('name') === 'logoGroup') {
        continue;
      }
      object.set({
        fill: this.colors[textColor],
        stroke: this.colors[textColor],
      });
      // if (this.typeGuardGroup(object) && object.get('name') === 'logoGroup') {
      //   for (let obj of object.getObjects()) {
      //     obj.set({
      //       fill: this.colors[textColor],
      //       stroke: this.colors[textColor],
      //     });
      //     if (this.typeGuardGroup(obj) && obj.get('name')?.includes('logo')) {
      //       for (let o of obj.getObjects()) {
      //         if (o.get('width') === 100 && o.get('height') === 100) {
      //           o.set({
      //             fill: 'transparent',
      //             stroke: 'transparent',
      //           });
      //         } else {
      //           o.set({
      //             fill: this.colors[textColor],
      //             stroke: this.colors[textColor],
      //           });
      //         }
      //       }
      //     }
      //   }
      // }
    }
    this.canvas!.renderAll();
  }

  public static validateBackgroundColor(
    color: string
  ): color is 'black' | 'white' {
    if (['black', 'white'].includes(color)) {
      return true;
    }
    return false;
  }

  public validateTextColor(
    color: string
  ): color is
    | 'taintedBlue'
    | 'mintGreen'
    | 'darkBlue'
    | 'lightBlue'
    | 'petrolGreen'
    | 'green'
    | 'purple'
    | 'pink'
    | 'red'
    | 'orange'
    | 'black'
    | 'white' {
    if (
      [
        'taintedBlue',
        'mintGreen',
        'darkBlue',
        'lightBlue',
        'petrolGreen',
        'green',
        'purple',
        'pink',
        'red',
        'orange',
        'black',
        'white',
      ].includes(color)
    ) {
      return true;
    }
    return false;
  }

  private typeGuardGroup(object?: fabric.Object): object is fabric.Group {
    if ((object as fabric.Group)._objects) {
      return true;
    }
    return false;
  }

  public calculateCiBorder(borderWidth: number, maxWidth: number) {
    let widest = [];

    //get all canvas text objects
    let objsText: any = this.canvas!.getObjects().filter((o: any) => {
      // console.log(o.width * o.scaleX);
      if (o.get('type') === 'textbox') {
        return o;
      }
      return false;
    });
    let largestTextObjectWidth = 0;
    if (objsText.length > 0) {
      for (let i = 0; i < objsText.length; i++) {
        if (objsText.excludeFromExport == true) {
          continue;
        }

        let width =
          objsText[i].getBoundingRect().left +
          Math.max.apply(null, objsText[i].__lineWidths);
        if (largestTextObjectWidth < width) {
          largestTextObjectWidth = width;
        }
      }
      widest.push(largestTextObjectWidth + borderWidth);
    }

    let objsCustomeLogo: any = this.canvas!.getObjects().filter((o: any) => {
      if (o.get('name') === 'logoGroup') {
        return o;
      }
      return false;
    });
    if (objsCustomeLogo.length > 0) {
      for (let i = 0; i < objsCustomeLogo.length; i++) {
        let objsCostumeLogo = objsCustomeLogo[i]
          .getObjects()
          .filter((o: any) => {
            if (o.get('name')?.includes('logo')) {
              return o;
            }
            return false;
          });
        let objsCostumeLogoWidth = 0;
        if (objsCostumeLogo.length > 0) {
          objsCostumeLogoWidth = objsCostumeLogo[0].getScaledWidth();
        }

        widest.push(
          objsCustomeLogo[i].left + objsCostumeLogoWidth + borderWidth
        );
      }
    }

    let objsLogo: any = this.canvas!.getObjects().filter((o: any) => {
      if (o.get('type') === 'logo') {
        return o;
      }
      return false;
    });
    let largestLogoObjectWidth = 0;
    if (objsLogo.length > 0) {
      for (let i = 0; i < objsLogo.length; i++) {
        let width = objsLogo[i].left + objsLogo[i].width;

        if (largestLogoObjectWidth < width) {
          largestLogoObjectWidth = width;
        }
      }

      widest.push(largestLogoObjectWidth + borderWidth);
    }

    const maxWidest = Math.max.apply(null, widest);

    const left = objsText.length
      ? objsText[0].get('left')!
      : objsLogo[0].get('left')!;

    this.canvas!.setWidth(Math.min(maxWidest, left + maxWidth));
  }
}
