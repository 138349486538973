import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { fileCreationRequest } from '@app/lib/model/fileCreationRequest';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class FileCreationService {
  constructor(protected httpClient: HttpClient) {}

  public sendFileCreationRequest(
    fileCreationRequest: fileCreationRequest
  ): Observable<any> {
    let headers = new HttpHeaders();

    headers.set('Content-Type', 'application/json');

    return this.httpClient.post(
      environment.apiDomain + '/',
      fileCreationRequest,
      {
        headers: headers,
      }
    );
  }

  public sendEPSConversionRequest(epsFile: File): Observable<any> {
    let headers = new HttpHeaders();

    headers.set('Content-Type', 'multipart/form-data');

    let formData = new FormData();
    formData.append('file', epsFile);

    return this.httpClient.post(
      environment.apiDomain + '/createSVG.php',
      formData,
      {
        headers: headers,
      }
    );
  }
}
