<div class="form-hint">
  <div class="container bg-{{ backgroundColor }}">
    <div class="row">
      <div class="col-10 offset-1">
        <h3>Hinweise zur Verwendung des Logos:</h3>
      </div>
      <div class="col-5 offset-1">
        <ul>
          <li>
            Ein einheitliches Erscheinungsbild ist sehr wichtig. Es garantiert, dass wir als starke Organisation nach
            innen und
            außen wahrgenommen werden. Es stellt sicher, dass man uns zuverlässig erkennt. Das ist zu unser aller
            Nutzen. Wir sind
            die RPTU.
          </li>
          <li>
            Das Logo der RPTU besteht aus einer Bild- und einer Wortmarke. Die Bildmarke variiert die vier Buchstaben
            des Kurznamens
            innerhalb eines vorgegebenen Rasters. Dabei sind nicht alle möglichen Kombinationen zugelassen. Das
            Logo-Generator
            bietet Ihnen nur zugelassene Kombinationen an.
          </li>
        </ul>
      </div>
      <div class="col-5">
        <ul>
          <li>
            Anstelle der Wortmarke, dem vollständigen Namen der Universität, kann auch eine Gliederung der Universität
            oder ein
            externer Partner treten. Der Logo-Generator unterstützt Sie auch bei der Erstellung von Logo-Kombinationen.
          </li>
          <li>
            Für die RPTU wurden zwölf Farben ausgewählt, die zu sechs Farbpaaren zusammengestellt werden können. Der
            Logo-Generator
            unterstützt Sie dabei, die passenden Farben auszuwählen und achtet auf hinreichenden Kontrast.
          </li>
          <li>
            Alle Regeln zur Nutzung des Logos sowie zu allen Variationsmöglichkeiten sind im Brand Manual der RPTU
            definiert. Bei
            Fragen hilft die Universitätskommunikation gerne weiter.
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
