<section class="page-footer">
  <div class="container">
    <div class="row">
      <div class="col-8 offset-1">
        <h2>Haben Sie Fragen?</h2>
        <p>
          Bei allen Fragen zur Corporate Identity der RPTU (Logo, Farben, Gestaltungsmöglichkeiten…) steht Ihnen das
          Team der
          Universitätskommunikation mit Rat und Tat zur Seite.
        </p>
      </div>
      <div class="col-2 d-flex justify-content-end align-items-start">
        <a href="mailto:marketing@uni-kl.de" class="button">Kontakt aufnehmen</a>
      </div>
    </div>
  </div>
</section>
