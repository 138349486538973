<section class="page-header">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <h1>
          <a *ngIf="router.url !== '/'" href="/">{{ headline }}</a>
          <a *ngIf="router.url === '/'">{{ headline }}</a>
        </h1>
      </div>
      <div class="col-lg-6 offset-lg-2" [innerHTML]="bodytext"></div>
    </div>
  </div>
</section>
