import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { fabric } from 'fabric';
import { Group, IEvent, IImageOptions, ITextOptions } from 'fabric/fabric-impl';
import { ImageUploadModalComponent } from '@app/theme/components/image-upload-modal/image-upload-modal.component';
import {} from 'css-font-loading-module';
import { FormControl } from '@angular/forms';
import { needTrue } from '@app/lib/validator/NeedTrue';
import { CanvasExportService } from '@app/lib/service/canvasExportService';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements OnInit {
  @Output('brandingAdded') brandingAdded: EventEmitter<
    'intern' | 'extern' | 'standard'
  > = new EventEmitter<'intern' | 'extern' | 'standard'>();
  @ViewChild('backgroundImage') backgroundImage!: ElementRef;
  @ViewChild('backgroundErrorImage') backgroundErrorImage!: ElementRef;
  imageUploadModal?: ImageUploadModalComponent;

  _canvas?: fabric.Canvas;

  canvasWidth: number = 580;
  canvasHeight: number = 510;

  canvasPadding: number = 90;

  movementArea?: { left: number; right: number };

  breakpoints?: { top: number; bottom: number };
  grid?: number[];

  logoType: 'Bild-Marke' | 'Wort-Bild-Marke' = 'Wort-Bild-Marke';
  logoTypeForDropdown: 'Intern' | 'Extern' | 'Standard' = 'Standard';
  imageBrandWidth: number = 0;
  wordBrandWidth: number = 0;
  letterWidth: number = 0;
  letterSpacing: number = 0;
  institutionNameLinesCounter = 0;
  contactNameLinesCounter = 0;
  logo: any;
  logoInvalid: any;

  gridPositions: {
    R: { x: number; y?: number };
    P: { x: number; y?: number };
    T: { x: number; y?: number };
    U: { x: number; y: number };
  } = {
    R: { x: 0 },
    P: { x: 1 },
    T: { x: 2 },
    U: { x: 3, y: 1 },
  };

  imageDefaultHeight: number = 0;

  selectedBranding?: {
    institution?: fabric.Text;
    name: fabric.Text;
    logo?: fabric.Group;
    logoInvalid?: fabric.Group;
    groupName: string;
    divider: fabric.Rect;
    width: number;
    checkmarks: fabric.Group[];
  };

  logoValid: FormControl = new FormControl(false, needTrue);

  public ngOnInit(): void {
    setTimeout(() => {
      this.calcCanvasPadding();
      this.canvasHeight = this.canvasPadding * (5 + 2 / 3);
      const canvasOptions = {
        backgroundColor: '#ffffff',
        selection: false,
        preserveObjectStacking: true,
        width: this.canvasWidth,
        height: this.canvasHeight,
      };

      this._canvas = new fabric.Canvas('logo-generator', canvasOptions);

      this.grid = [
        this.canvasPadding,
        this.canvasPadding * 2 + this.canvasPadding / 3,
        // this.canvasPadding * 3 + (this.canvasPadding * 2) / 3,
        this.canvasHeight - 2 * this.canvasPadding,
      ];

      this.breakpoints = {
        top: this.grid[0] + (this.grid[1] - this.grid[0]) / 2,
        bottom: this.grid[1] + (this.grid[2] - this.grid[1]) / 2,
      };

      this.initCanvas();

      this._canvas?.on('object:moving', (event: IEvent) => {
        this.moveInBoundaries(event.target);
      });

      this._canvas?.on('object:moved', () => {
        this.isEverythingValid();
      });

      this._canvas?.on('selection:created', (event: IEvent) => {
        this.setMovementArea();
      });

      this._canvas?.on('selection:cleared', (event: IEvent) => {
        this.movementArea = undefined;
      });

      this._canvas?.on('selection:updated', (event: IEvent) => {
        this.setMovementArea();
      });

      let that = this;
      this._canvas?.on('mouse:over', function (e) {
        if (
          e.target &&
          e.target!.name &&
          e.target!.name !== 'U' &&
          that.gridPositions[
            e.target.name!.charAt(0) as keyof typeof that.gridPositions
          ] &&
          e.target.name!.length > 1
        ) {
          e.target!.set('fill', '#404040');
          that._canvas?.renderAll();
        }
      });
      this._canvas?.on('mouse:out', function (e) {
        if (
          e.target &&
          e.target!.name &&
          e.target!.name !== 'U' &&
          that.gridPositions[
            e.target.name!.charAt(0) as keyof typeof that.gridPositions
          ] &&
          e.target.name!.length > 1
        ) {
          e.target!.set('fill', '#A8ECCA');
          that._canvas?.renderAll();
        }
      });

      const removeEmptyLines = (str: string) =>
        str
          .split(/\r?\n/)
          .filter((line: any) => line.trim() !== '')
          .join('\n');

      this._canvas?.on('text:changed', (event: IEvent) => {
        if (
          event.target &&
          (this.selectedBranding!.institution === event.target ||
            this.selectedBranding!.name === event.target)
        ) {
          const customLogo = that._canvas
            ?.getObjects()!
            .filter((x) => x.name && x.name!.includes('logoGroup'))!;

          // if (customLogo.length) {
          //   return false;
          // }
          this.adjustBrandingPositions();
          //this.adjustBrandingPositions(true);
          this.selectedBranding!.institution!.setCoords();
          this.selectedBranding!.name.setCoords();
          this.selectedBranding!.logo!.setCoords();
          this.selectedBranding!.logoInvalid!.setCoords();

          // this.selectedBranding!.name.set(
          //   'text',
          //   removeEmptyLines(this.selectedBranding!.name.get('text') || '')
          // );
        }
        return true;
      });

      this._canvas?.on('mouse:out', (event: IEvent) => {
        if (
          event.target &&
          this.selectedBranding &&
          (this.selectedBranding!.institution === event.target ||
            this.selectedBranding!.name === event.target)
        ) {
          // this.selectedBranding!.name.set(
          //   'text',
          //   removeEmptyLines(this.selectedBranding!.name.get('text') || '')
          // );
          const currentText = <fabric.Textbox>event.target;
          if (currentText.hiddenTextarea) {
            currentText.hiddenTextarea!.value = removeEmptyLines(
              currentText.get('text') || ''
            );
            currentText.updateFromTextArea();
          }
          // this.selectedBranding!.institution!.set(
          //   'text',
          //   removeEmptyLines(
          //     this.selectedBranding!.institution!.get('text') || ''
          //   )
          // );

          this.contactNameLinesCounter =
            (
              this.selectedBranding!.name!.get('text')!.match(
                new RegExp('\n', 'g')
              ) || []
            ).length + 1;

          this.institutionNameLinesCounter =
            (
              this.selectedBranding!.institution!.get('text')!.match(
                new RegExp('\n', 'g')
              ) || []
            ).length + 1;

          that._canvas?.renderAll();

          const customLogo = that._canvas
            ?.getObjects()!
            .filter((x) => x.name && x.name!.includes('logoGroup'))!;

          if (customLogo.length) {
            return false;
          }

          setTimeout(() => {
            if (
              this.contactNameLinesCounter + this.institutionNameLinesCounter >
              3
            ) {
              that._canvas?.remove(that.logo);
              const uploadBoxInvalid = that._canvas
                ?.getObjects()!
                .filter((x) => x.name && x.name!.includes('uploadInvalidBox'))!;
              if (!uploadBoxInvalid.length) {
                that._canvas?.add(that.logoInvalid);
              }
            } else {
              const uploadBox = that._canvas
                ?.getObjects()!
                .filter((x) => x.name && x.name!.includes('uploadBox'))!;
              if (!uploadBox.length) {
                that._canvas?.add(that.logo);
                that._canvas?.remove(that.logoInvalid);
              }
            }

            this.adjustBrandingPositions();
            this.adjustBrandingPositions(true);
            this.selectedBranding!.institution!.setCoords();
            this.selectedBranding!.name.setCoords();
            this.selectedBranding!.logo!.setCoords();
            this.selectedBranding!.logoInvalid!.setCoords();

            that._canvas?.renderAll();
          }, 150);
        }
        return true;
      });
    }, 1);
  }

  private calcCanvasPadding(): void {
    const wrapper = document.querySelector('.col-10.offset-1')!;
    if (wrapper) {
      const elementStyle = window.getComputedStyle(wrapper);
      const elementWidth = Number(elementStyle.width.replace('px', ''));
      const elementPadding =
        Number(elementStyle.paddingLeft.replace('px', '')) +
        Number(elementStyle.paddingRight.replace('px', ''));
      this.canvasWidth = elementWidth - elementPadding;

      // -3.9 so we get to 224 height
      this.canvasPadding = this.canvasWidth / 16.968 - 3.9;
      // +1.5 so we get to 184 width
      this.letterWidth = (56 / 63) * this.canvasPadding + 1.5;
      this.letterSpacing = (5.35 / 63) * this.canvasPadding;
      this.imageBrandWidth = 4 * this.letterWidth + 3 * this.letterSpacing;
    }
  }

  private isLogoValid(): boolean {
    let inRow = 0,
      lastPosition,
      currentPositionR,
      currentPositionT,
      lettersPositioned = {
        R: false,
        P: false,
        T: false,
        U: true,
      };
    if (this.logoType === 'Wort-Bild-Marke') {
      //changed because all T's should be allowed now even in Wort-Bild-Marke
      //this.updatePositionColors('P', true, 1);
      this.updatePositionColors('P');
    } else {
      this.updatePositionColors('P');
    }
    for (let letter of <('R' | 'P' | 'T' | 'U')[]>(
      Object.keys(this.gridPositions)
    )) {
      let currentPosition = this.gridPositions[letter].y;

      if (letter === 'R') {
        currentPositionR = currentPosition;
      }
      if (letter === 'T') {
        currentPositionT = currentPosition;
      }

      if (currentPosition !== undefined) {
        // no 3 letters in a row

        if (lastPosition == undefined) {
          inRow++;
        } else {
          if (currentPosition == lastPosition) {
            inRow++;
            if (inRow === 2) {
              if (letter === 'T' && currentPosition === 1) {
                let position = 1;
                while (position === 1) {
                  position = Math.floor(Math.random() * 3);
                }
                this.gridPositions.P.y = position;
                this.setOrUpdateLetter('P', position);
                this.isEverythingValid();
                return this.logoValid.value;
              } else {
                this.updatePositionColors(letter, false, currentPosition);
              }
            }

            // added this logic to block the "P"-letter if R AND T are selected in one row
          } else if (currentPositionR == currentPositionT) {
            this.updatePositionColors('U', false, currentPositionR);
          } else {
            if (this.logoType === 'Wort-Bild-Marke' && letter === 'P') {
              //changed because all T's should be allowed now even in Wort-Bild-Marke
              //this.updatePositionColors('P', true, 1);
              this.updatePositionColors('P');
            } else {
              this.updatePositionColors(letter);
            }
            inRow = 1;
          }
        }
        lettersPositioned[letter] = true;
      } else {
        lettersPositioned[letter] = false;
      }

      lastPosition = currentPosition;
    }

    let isAnythingPositionOne = false;
    for (let letter of <('R' | 'P' | 'T' | 'U')[]>(
      Object.keys(this.gridPositions)
    )) {
      let currentPosition = this.gridPositions[letter].y;

      if (currentPosition === 1 && letter !== 'U') {
        isAnythingPositionOne = true;

        if (letter === 'R') {
          this.updatePositionColors('U', false, currentPosition);
          this.updatePositionColors('P', false, currentPosition);
        }
        if (letter === 'P') {
          this.updatePositionColors('R', false, currentPosition);
          this.updatePositionColors('P', false, currentPosition);
        }
        if (letter === 'T') {
          this.updatePositionColors('U', false, currentPosition);
          this.updatePositionColors('R', false, currentPosition);
        }
      }
    }

    // if (!isAnythingPositionOne) {
    //   this.updatePositionColors('T', true, 0);
    //   this.updatePositionColors('T', true, 1);
    //   this.updatePositionColors('T', true, 2);
    // }

    if (!lettersPositioned.R || !lettersPositioned.P || !lettersPositioned.T) {
      return false;
    } else {
      if (
        (this.gridPositions.R.y! === this.gridPositions.P.y! &&
          this.gridPositions.R.y! === this.gridPositions.T.y!) ||
        (this.gridPositions.P.y! === this.gridPositions.T.y! &&
          this.gridPositions.P.y! === this.gridPositions.U.y!)
      ) {
        return false;
      } else {
        return true;
      }
    }
  }

  private isBrandingValid(): boolean {
    if (this.selectedBranding!.name.get('fill') === '#006B6B') {
      return false;
    } else if (
      !this.selectedBranding!.institution ||
      this.selectedBranding!.institution.get('fill') === '#006B6B'
    ) {
      return false;
    } else {
      /*
    else if (
      !this.selectedBranding!.logo ||
      (this.selectedBranding!.logo.get('hoverCursor') === 'pointer' &&
        this.selectedBranding!.logo.get('opacity') === 1)
    ) {
      return false;
    }
    */
      return true;
    }
  }

  private isEverythingValid(): void {
    if (this.logoType === 'Bild-Marke') {
      this.logoValid.patchValue(this.isLogoValid() && this.isBrandingValid());
    } else {
      this.logoValid.patchValue(this.isLogoValid());
    }
  }

  private updatePositionColors(
    letter: 'R' | 'P' | 'T' | 'U',
    reverse: boolean = false,
    yPosition?: number
  ): void {
    let blockColor: string,
      blockBackground: HTMLImageElement,
      allowColor: string,
      allowBackground: HTMLImageElement,
      evented: boolean;
    if (!reverse) {
      blockColor = '#ffbfcf';
      blockBackground = this.backgroundErrorImage.nativeElement;
      allowColor = '#A8ECCA';
      allowBackground = this.backgroundImage.nativeElement;
      evented = true;
    } else {
      blockColor = '#A8ECCA';
      blockBackground = this.backgroundImage.nativeElement;
      allowColor = '#ffbfcf';
      allowBackground = this.backgroundErrorImage.nativeElement;
      evented = false;
    }
    let blockedLetter = letter;
    switch (letter) {
      case 'U':
        blockedLetter = 'P';
        break;
      case 'T':
        blockedLetter = 'R';
        break;
      case 'P':
        blockedLetter = 'T';
    }
    let objects = this._canvas!.getObjects().filter((x) =>
      x.get('name')?.includes(blockedLetter + '-')
    );

    let blockedObjects: fabric.Object[] = [];
    if (yPosition !== undefined) {
      blockedObjects = objects.filter((x) =>
        x.get('name')!.includes('-' + String(yPosition))
      );

      // removed because all T's should be allowed now even in Wort-Bild-Marke
      // if (
      //   this.logoType === 'Wort-Bild-Marke' &&
      //   blockedLetter === 'T' &&
      //   !reverse
      // ) {
      //   let topBottomLetters = objects.filter(
      //     (x) =>
      //       x.get('name')!.includes('T-0') || x.get('name')!.includes('T-2')
      //   );
      //   for (let i in topBottomLetters) {
      //     if (!blockedObjects.includes(topBottomLetters[i])) {
      //       blockedObjects.push(topBottomLetters[i]);
      //     }
      //   }
      // }
      this.changePositionColors(
        blockedObjects,
        blockBackground,
        blockColor,
        !evented
      );
    }
    let allowedObjects = objects.filter((x) => !blockedObjects.includes(x));

    // console.log(this.gridPositions);
    // console.log(letter);
    // console.log(blockedLetter);

    // console.log(blockedObjects);
    // console.log(allowedObjects);
    // console.log(reverse);
    // console.log(yPosition);

    let letterObjectsR0 = this._canvas!.getObjects().filter((x) =>
      x.get('name')?.includes('R-0')
    );
    let letterObjectsR1 = this._canvas!.getObjects().filter((x) =>
      x.get('name')?.includes('R-1')
    );
    let letterObjectsR2 = this._canvas!.getObjects().filter((x) =>
      x.get('name')?.includes('R-2')
    );
    let rowCounter = { 0: 0, 1: 0, 2: 0 };
    for (let letter of <('R' | 'P' | 'T' | 'U')[]>(
      Object.keys(this.gridPositions)
    )) {
      let currentPosition = this.gridPositions[letter].y;
      if (currentPosition !== undefined) {
        rowCounter[currentPosition as keyof typeof rowCounter]++;
      }
    }
    if (rowCounter[0] < 2) {
      this.changePositionColors(
        letterObjectsR0,
        allowBackground,
        allowColor,
        evented
      );
    }
    if (rowCounter[1] < 2) {
      this.changePositionColors(
        letterObjectsR1,
        allowBackground,
        allowColor,
        evented
      );
    }
    if (rowCounter[2] < 2) {
      this.changePositionColors(
        letterObjectsR2,
        allowBackground,
        allowColor,
        evented
      );
    }

    // removed because all T's should be allowed now even in Wort-Bild-Marke
    // if (
    //   this.logoType === 'Wort-Bild-Marke' &&
    //   blockedLetter === 'T' &&
    //   reverse
    // ) {
    //   let topBottomLetters = objects.filter(
    //     (x) => x.get('name')!.includes('T-0') || x.get('name')!.includes('T-2')
    //   );
    //   for (let i in topBottomLetters) {
    //     if (!allowedObjects.includes(topBottomLetters[i])) {
    //       allowedObjects.push(topBottomLetters[i]);
    //     }
    //   }
    // }
    this.changePositionColors(
      allowedObjects,
      allowBackground,
      allowColor,
      evented
    );
    this._canvas!.renderAll();
  }

  private changePositionColors(
    objects: fabric.Object[],
    background: HTMLImageElement,
    fill: string,
    evented: boolean
  ): void {
    for (let object of objects) {
      if (this.typeGuardImage(object)) {
        const width = object.get('width')!;
        const height = object.get('height')!;
        const cropX = object.get('cropX')!;
        const cropY = object.get('cropY')!;
        object.setElement(background);
        object.set({
          width: width,
          height: height,
          cropX: cropX,
          cropY: cropY,
        });
      } else {
        object.set({
          fill: fill,
          evented: evented,
        });
      }
    }
  }

  /**
   * Scale logo/upload box depending on line breaks
   */
  private adjustBrandingPositions(adjustLogoInvalid = false) {
    const institution = this.selectedBranding!.institution,
      name = this.selectedBranding!.name,
      checkmarks = this.selectedBranding!.checkmarks!;

    var logo = this.selectedBranding!.logo!;

    var logoInvalid = this.selectedBranding!.logoInvalid!;

    // adjust name textbox position
    let occupiedLines =
      institution!.get('textLines').length! + name.get('textLines').length!;
    let nameTop = this.grid![2] - 6;
    if (institution!.get('textLines').length! === 2) {
      nameTop = this.grid![1] - 6;
    } else if (institution!.get('textLines').length! === 1) {
      nameTop = this.grid![0] - 5 + (41 / 62) * this.canvasPadding;
    } else if (institution!.get('textLines').length! === 3) {
      nameTop = this.grid![1] - 5 + (41 / 62) * this.canvasPadding;
    }
    name.set({
      top: nameTop,
    });

    // variables for checkboxes and logo
    let targetHeight, top, opacity;
    if (occupiedLines > 2 && occupiedLines < 5) {
      targetHeight = this.canvasPadding;
      top = this.grid![2];
    } else {
      targetHeight = this.canvasPadding * (7 / 3);
      top = this.grid![1];
    }
    if (occupiedLines > 4) {
      opacity = 0;
    } else {
      opacity = 1;
    }

    // adjust checkmark positions
    let logoIndex = 1;
    let nameIndex = 0;
    if (checkmarks.length === 3) {
      logoIndex = 2;
      nameIndex = 1;
    }
    checkmarks[logoIndex].set({
      top: top + targetHeight / 2 - 12,
      opacity: opacity,
    });
    checkmarks[nameIndex].set({
      top: nameTop + 7,
    });

    // adjust logo positions
    const scale = this.calcLogoScaling(
      targetHeight,
      logo!.get('height')!,
      logo!.get('width')!,
      logo!.get('name') === 'uploadBox'
    );

    logo!.set({
      top: top,
      scaleY: occupiedLines <= 2 ? 1 : scale,
      evented: Boolean(opacity),
      opacity: opacity,
    });
    if (top - logo.getScaledHeight() > 0) {
      logo!.set({
        top:
          this._canvas!.getHeight() -
          this.canvasPadding -
          logo.getScaledHeight(),
      });
    }

    // adjust logoInvalid positions
    const scaleLogoInvalid = this.calcLogoScaling(
      targetHeight,
      logoInvalid!.get('height')!,
      logoInvalid!.get('width')!,
      false
    );
    logoInvalid!.set({
      top: top,
      scaleY: scaleLogoInvalid,
      evented: Boolean(opacity),
      opacity: opacity,
    });

    // fix errors that occure due to scaling
    if (logo!.get('name') === 'uploadBox') {
      logo!
        .getObjects()
        .filter((x) => x.get('name')! === 'uploadGroup')[0]
        .set({
          scaleY: 1 / scale,
        });
      let background = logo!
        .getObjects()
        .filter((x) => x.get('name')! === 'background')[0];
      background.set({
        scaleY: 1 / scale,
        top: targetHeight / scale / -2,
      });
      let backgroundImages = (background as fabric.Group).getObjects();
      let left = backgroundImages[0].get('left')!;
      let opacity;
      if (occupiedLines > 2 && occupiedLines < 5) {
        opacity = 0;
      } else {
        opacity = 1;
      }
      this.changeBackgroundOpacity(
        backgroundImages,
        left,
        targetHeight,
        opacity
      );
    } else {
      const deleteGroup = logo!
        .getObjects()
        .filter((x) => x.get('name')! === 'deleteGroup')[0];
      const logoImage = logo.getObjects().filter((x) => x !== deleteGroup)[0];
      let currentHeight, currentWidth;
      if (occupiedLines < 3) {
        currentHeight = logoImage.get('height')!;
        currentWidth = logoImage.get('width')!;
      } else {
        currentHeight = logoImage.getScaledHeight();
        currentWidth = logoImage.getScaledWidth();
      }
      const imageScale = this.calcLogoScaling(
        targetHeight,
        logoImage.get('height')!,
        logoImage.get('width')!,
        false
      );
      logoImage.set({
        scaleX: imageScale,
      });
      if (logoImage.get('name')! === 'logoSmall') {
        logoImage.set({
          scaleY: imageScale,
        });
        const groupWidth = logo.getScaledWidth();
        const logoWidth = logoImage.getScaledWidth();
        if (groupWidth < logoWidth) {
          logo.set({
            width: logoWidth + 10 + deleteGroup.get('width')!,
            height: logoImage.getScaledHeight(),
            top: top,
          });
          if (top - logo.getScaledHeight() > 0) {
            logo!.set({
              top:
                this._canvas!.getHeight() -
                this.canvasPadding -
                logo.getScaledHeight(),
            });
          }
          logoImage.set({
            left: logo.get('width')! / -2,
            top: logo.get('height')! / -2,
            name: 'logoBig',
          });
        }
      }
      if (deleteGroup) {
        deleteGroup.set({
          scaleY: occupiedLines <= 2 ? 1 : 1 / scale,
          top:
            occupiedLines <= 2
              ? -((deleteGroup.get('height')! * 1) / 1 / 2)
              : -((deleteGroup.get('height')! * 1) / scale / 2),
          left: logoImage.get('left')! + logoImage.getScaledWidth() + 10,
        });
        deleteGroup.setCoords();
        logo!.setCoords();
        logo!.setObjectsCoords();
      }
    }

    // fix errors that occure due to scaling
    if (logoInvalid!.get('name') === 'uploadInvalidBox') {
      // logoInvalid!
      //   .getObjects()
      //   .filter((x) => x.get('name')! === 'uploadGroup')[0]
      //   .set({
      //     scaleY: 1 / scale,
      //   });
      let background = logoInvalid!
        .getObjects()
        .filter((x) => x.get('name')! === 'background')[0];
      background.set({
        scaleY: 1 / scale,
        top: targetHeight / scale / -2,
      });
      let backgroundImages = (background as fabric.Group).getObjects();
      let left = backgroundImages[0].get('left')!;
      let opacity;
      if (occupiedLines > 2 && occupiedLines < 5) {
        opacity = 0;
      } else {
        opacity = 1;
      }
      this.changeBackgroundOpacity(
        backgroundImages,
        left,
        targetHeight,
        opacity
      );
    } else {
      const deleteGroup = logoInvalid!
        .getObjects()
        .filter((x) => x.get('name')! === 'deleteGroup')[0];
      const logoImage = logoInvalid
        .getObjects()
        .filter((x) => x !== deleteGroup)[0];
      let currentHeight, currentWidth;
      if (occupiedLines < 3) {
        currentHeight = logoImage.get('height')!;
        currentWidth = logoImage.get('width')!;
      } else {
        currentHeight = logoImage.getScaledHeight();
        currentWidth = logoImage.getScaledWidth();
      }
      logoImage.set({
        scaleX: this.calcLogoScaling(
          targetHeight,
          logoImage.get('height')!,
          logoImage.get('width')!,
          false
        ),
      });
      if (deleteGroup) {
        deleteGroup.set({
          scaleY: 1 / scale,
          top: -((deleteGroup.get('height')! * 1) / scale / 2),
          left: logoImage.get('left')! + logoImage.getScaledWidth() + 10,
        });
        deleteGroup.setCoords();
        logoInvalid!.setCoords();
        logoInvalid!.setObjectsCoords();
      }
    }

    this.logoInvalid = logoInvalid;
    this.logo = logo;
  }

  private changeBackgroundOpacity(
    backgroundImages: fabric.Object[],
    left: number,
    targetHeight: number,
    opacity: number
  ) {
    let line = 0;
    for (let image of backgroundImages) {
      if (image.get('left')! < left) {
        line++;
      }
      left = image.get('left')!;
      image.set({
        height:
          opacity === 0
            ? targetHeight
            : Math.abs(line * targetHeight - this.imageDefaultHeight),
        opacity: opacity === 0 && line === 1 ? 0 : 1,
      });
    }
  }

  /**
   * prefill canvas with the letters
   */
  private initCanvas(): void {
    if (this._canvas) {
      this.setLetterBackground();

      this.setLetters();

      // Wort-Marke hinzufügen
      if (this.logoType === 'Wort-Bild-Marke') {
        this.addBrandText();
      }
    }
  }

  private setLetterBackground(): void {
    const backgrounds: fabric.Image[] = [];
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < 3; j++) {
        let letter;
        switch (j) {
          case 1:
            letter = 'P';
            break;
          case 2:
            letter = 'T';
            break;
          default:
            letter = 'R';
            break;
        }
        backgrounds.push(
          new fabric.Image(this.backgroundImage.nativeElement, {
            top: this.grid![i],
            left:
              this.canvasPadding + j * (this.letterWidth + this.letterSpacing),
            selectable: false,
            excludeFromExport: true,
            hoverCursor: 'default',
            width: this.letterWidth + this.letterSpacing,
            height: this.canvasPadding,
            cropX: j * 3.5,
            cropY: i + i * 0.7,
            name: 'background-' + letter + '-' + i,
          })
        );
        if (i < 2) {
          backgrounds.push(
            new fabric.Image(this.backgroundImage.nativeElement, {
              top: this.grid![i] + this.canvasPadding,
              left:
                this.canvasPadding +
                j * (this.letterWidth + this.letterSpacing),
              selectable: false,
              excludeFromExport: true,
              hoverCursor: 'default',
              width: this.letterWidth + this.letterSpacing,
              height: this.canvasPadding / 3,
              cropX: j * 3.5,
              cropY: (i + 1) * 4 - i * 2.5,
            })
          );
        }
      }
    }
    this._canvas!.add(...backgrounds);
  }

  private setLetters(): void {
    const staticLetterOptions: Partial<Group> = {
      selectable: false,
      hoverCursor: 'pointer',
      fill: '#A8ECCA',
      evented: true,
      excludeFromExport: true,
    };

    const imageOptions: IImageOptions = {
      height: this.canvasPadding,
      width: (56 / 63) * this.canvasPadding,
    };

    for (let letter of ['r', 'p', 't']) {
      let width: number | undefined;
      let height: number | undefined;
      for (let i = 0; i < 3; i++) {
        fabric.loadSVGFromURL(
          '../assets/img/prefill/letter-' + letter + '-new.svg',
          (objects, options) => {
            const svg = fabric.util.groupSVGElements(objects, imageOptions);
            if (!width || !height) {
              if (this.typeGuardGroup(svg)) {
                width = svg.get('width');
                height = svg.get('height');
              } else {
                width = svg.get('width');
                height = svg.get('height');
              }
            }
            svg.set(staticLetterOptions).set({
              top: this.grid![i],
              left:
                this.gridPositions[<'R' | 'P' | 'T' | 'U'>letter.toUpperCase()]
                  .x *
                  this.letterWidth +
                this.gridPositions[<'R' | 'P' | 'T' | 'U'>letter.toUpperCase()]
                  .x *
                  this.letterSpacing +
                this.canvasPadding,
              scaleX: imageOptions.width! / width!,
              scaleY: imageOptions.height! / height!,
              name: letter.toUpperCase() + '-' + i,
              strokeWidth: 0,
            });
            svg.on('mouseup', (event: fabric.IEvent) => {
              const letter = this._canvas!.getObjects().filter(
                (x) =>
                  x.get('name') === event.target!.get('name')!.split('-')[0]
              );
              if (letter[0]) {
                this.moveLetter(event.target!, letter[0]);
              } else {
                this.addLetter(event.target!, imageOptions);
              }
              this.isEverythingValid();
            });
            this._canvas?.add(svg);
            if ((svg as fabric.Object).get('name') === 'R-2') {
              setTimeout(() => {
                this.isEverythingValid();
              });
            }
          }
        );
      }
      this.addControlMarker(
        letter.toUpperCase(),
        this.gridPositions[<'R' | 'P' | 'T' | 'U'>letter.toUpperCase()].x *
          (this.letterWidth + this.letterSpacing) +
          this.canvasPadding +
          15,
        this.canvasPadding - 34
      );
    }

    fabric.loadSVGFromURL(
      '../assets/img/prefill/letter-u-new.svg',
      (objects, options) => {
        const svg = fabric.util.groupSVGElements(objects, imageOptions);
        let width: number | undefined;
        let height: number | undefined;
        if (!width || !height) {
          if (this.typeGuardGroup(svg)) {
            width = svg.get('width');
            height = svg.get('height');
          } else {
            width = svg.get('width');
            height = svg.get('height');
          }
        }
        svg.set({
          hoverCursor: 'default',
          selectable: false,
          top: this.grid![this.gridPositions.U.y],
          left:
            this.gridPositions.U.x * this.letterWidth +
            this.gridPositions.U.x * this.letterSpacing +
            this.canvasPadding,
          scaleX: imageOptions.width! / width!,
          scaleY: imageOptions.height! / height!,
          name: 'U',

          strokeWidth: 0,
        });
        this._canvas?.add(svg);
      }
    );
  }

  /**
   * move an existing letter on click
   * @param target
   * @param existingLetter
   */
  private moveLetter(
    target: fabric.Object,
    existingLetter: fabric.Object
  ): void {
    let letter = target.get('name')!.split('-');

    this.gridPositions[<'R' | 'P' | 'T'>letter[0]].y = Number(letter[1]);

    existingLetter.set({
      top: this.grid![Number(letter[1])],
    });
    existingLetter.setCoords();
    this._canvas!.renderAll();
  }

  /**
   * add a new letter on click
   * @param target
   * @param imageOptions
   */
  private addLetter(target: fabric.Object, imageOptions: IImageOptions): void {
    const selectedLetterOptions: Partial<Group> = {
      _controlsVisibility: {
        mtr: false,
        mt: false,
        mb: false,
        ml: false,
        mr: false,
        bl: false,
        br: false,
        tl: false,
        tr: false,
      },
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      lockSkewingX: true,
      lockSkewingY: true,
      lockMovementX: true,
    };

    let letter = target.get('name')!.split('-');

    this.gridPositions[<'R' | 'P' | 'T'>letter[0]].y = Number(letter[1]);

    fabric.loadSVGFromURL(
      '../assets/img/prefill/letter-' + letter[0].toLowerCase() + '-new.svg',
      (objects, options) => {
        const svg = fabric.util.groupSVGElements(objects, imageOptions);
        let width: number | undefined;
        let height: number | undefined;
        if (!width || !height) {
          if (this.typeGuardGroup(svg)) {
            width = svg.get('width');
            height = svg.get('height');
          } else {
            width = svg.get('width');
            height = svg.get('height');
          }
        }
        svg.set(selectedLetterOptions).set({
          top: this.grid![this.gridPositions[<'R' | 'P' | 'T'>letter[0]].y!],
          left:
            this.gridPositions[<'R' | 'P' | 'T'>letter[0]].x *
              this.letterWidth +
            this.gridPositions[<'R' | 'P' | 'T'>letter[0]].x *
              this.letterSpacing +
            this.canvasPadding,
          scaleX: imageOptions.width! / width!,
          scaleY: imageOptions.height! / height!,
          name: letter[0],
          strokeWidth: 0,
          selectable: false,
          hoverCursor: 'normal',
        });
        if (this.logoType == 'Wort-Bild-Marke' && letter[0] === 'T') {
          svg.set({
            lockMovementY: true,
            selectable: false,
            hoverCursor: 'default',
          });
        }
        this._canvas?.add(svg);
        this.updateControlMarkerStatus('checked', letter[0]);
      }
    );
  }

  private setOrUpdateLetter(letter: 'R' | 'P' | 'T', yPosition: number) {
    let letterObjects = this._canvas!.getObjects().filter((x) =>
      x.get('name')?.includes(letter)
    );
    if (letterObjects.filter((x) => x.get('name') === letter).length) {
      letterObjects
        .filter((x) => x.get('name') === letter)[0]
        .set({
          top: this.grid![yPosition],
        })
        .setCoords();
    } else {
      let letterTarget = letterObjects.filter(
        (x) => x.get('name') === letter + '-' + String(yPosition)
      )[0];
      let imageOptions: IImageOptions = {
        width: letterTarget.get('width'),
        height: letterTarget.get('height'),
      };
      this.addLetter(letterTarget, imageOptions);
    }
  }

  /**
   * randomize letter y-positions
   */
  public randomizeLogo(): void {
    let validConfig = false;
    while (!validConfig) {
      this.randomizeLetterPositions();
      validConfig = this.isLogoValid();
    }
    this.isEverythingValid();
  }

  private randomizeLetterPositions(): void {
    let newR = Math.floor(Math.random() * 3);
    let newP = Math.floor(Math.random() * 3);
    let newT = Math.floor(Math.random() * 3);
    //changed because the T is not locked anymore
    // if (this.logoType === 'Bild-Marke') {
    //   // newT = Math.floor(Math.random() * 3);
    //   // if (newP === 1) {
    //   //   while (newT === 1) {
    //   //     newT = Math.floor(Math.random() * 3);
    //   //   }
    //   // }
    //   newT = 1;
    //   while (newP === 1) {
    //     newP = Math.floor(Math.random() * 3);
    //   }
    // } else {

    // }

    while (
      (newP === newR && newR === newT) ||
      (newP === 1 && newR === 1) ||
      (newP === 1 && newT === 1) ||
      (newT === 1 && newR === 1)
    ) {
      newP = Math.floor(Math.random() * 3);
      newR = Math.floor(Math.random() * 3);
    }
    if (
      this.gridPositions.R.y === newR &&
      this.gridPositions.P.y === newP &&
      this.gridPositions.T.y === newT
    ) {
      this.randomizeLetterPositions();
    } else {
      this.gridPositions.R.y = newR;
      this.gridPositions.P.y = newP;
      this.gridPositions.T.y = newT;
    }
    for (let key of <('R' | 'P' | 'T')[]>['R', 'P', 'T']) {
      this.setOrUpdateLetter(key, this.gridPositions[key].y!);
    }
    this._canvas?.renderAll();
  }

  private updateControlMarkerStatus(
    status: 'checked' | 'unchecked',
    markerName: string
  ): void {
    const marker = this._canvas!.getObjects().filter(
      (x) => x.get('name') === 'controlMarker-' + markerName
    )[0];

    for (let group of (marker as fabric.Group).getObjects()) {
      if (group.get('name') === status) {
        group.set({
          opacity: 1,
        });
      } else {
        group.set({
          opacity: 0,
        });
      }
    }
  }

  private addControlMarker(
    markerName: string,
    left: number,
    top: number
  ): fabric.Group {
    // unchecked
    let lightCircle = new fabric.Circle({
      stroke: '#F2F2F2',
      strokeWidth: 2,
      fill: 'transparent',
      radius: 12,
    });
    let index = new fabric.Text(
      String(
        this._canvas!.getObjects().filter((x) =>
          x.get('name')?.includes('controlMarker-')
        ).length + 1
      ),
      {
        fontFamily: 'RedHatText',
        fontWeight: 900,
        fontSize: 12,
        fill: '#B3B3B3',
      }
    );
    const markerUnchecked = new fabric.Group([lightCircle, index], {
      opacity: 1,
      selectable: false,
      name: 'unchecked',
    });
    index.set({
      left: index.get('width')! / -2,
      top: index.get('height')! / -2,
    });

    // group
    const markerGroup = new fabric.Group([markerUnchecked], {
      excludeFromExport: true,
      name: 'controlMarker-' + markerName,
      selectable: false,
      evented: false,
      left: left,
      top: top,
    });

    // checked
    let darkCircle = new fabric.Circle({
      stroke: 'black',
      strokeWidth: 2,
      fill: 'transparent',
      radius: 12,
    });
    fabric.loadSVGFromURL(
      '../assets/img/icn-checkmark.svg',
      (result: fabric.Object[], options: any) => {
        let checkmarkIcon = fabric.util.groupSVGElements(result);
        const markerChecked = new fabric.Group([darkCircle, checkmarkIcon], {
          opacity: 0,
          selectable: false,
          name: 'checked',
        });
        checkmarkIcon.set({
          fill: 'black',
          width: 7,
          height: 6,
          left: -3.5,
          top: -3,
        });
        markerGroup.add(markerChecked);
        markerChecked.set({
          left: markerChecked.get('width')! / -2,
          top: markerChecked.get('height')! / -2,
        });
      }
    );

    this._canvas!.add(markerGroup);

    return markerGroup;
  }

  private removeLineFromTextbox(
    textbox: fabric.Textbox,
    maxLines: number
  ): void {
    this._canvas!.setActiveObject(textbox);
    textbox.enterEditing();
    let hiddenTextarea = textbox.hiddenTextarea!;
    const lineRegex = new RegExp(/\n.*$/);
    for (let i = textbox.get('textLines').length; i > maxLines; i--) {
      hiddenTextarea.value = hiddenTextarea.value.replace(lineRegex, '');
    }
    textbox.updateFromTextArea();
    textbox.exitEditing();
  }

  private removeIncorrectLinesOnLogoUpload(): void {
    const institutionName = this.selectedBranding!.institution!,
      contactName = this.selectedBranding!.name;
    if (this.institutionNameLinesCounter > 1) {
      // remove line
      this.removeLineFromTextbox(<fabric.Textbox>institutionName, 1);
    }
    if (this.contactNameLinesCounter > 2) {
      // remove line
      this.removeLineFromTextbox(<fabric.Textbox>contactName, 2);
    }
    this.adjustBrandingPositions();
  }

  /**
   * generate a fabric object or group from the string and add it to canvas
   * @param imageData
   */
  public addLogo(imageData: string): void {
    this.removeIncorrectLinesOnLogoUpload();
    let imageType = imageData.split(';')[0];

    const logoHeight = this.selectedBranding!.logo!.getScaledHeight();
    const top = this.selectedBranding!.logo!.get('top')!;
    const left = this.selectedBranding!.logo!.get('left')!;

    let logoName = 'logoSmall';
    if (top === this.grid![1]) {
      logoName = 'logoBig';
    }

    if (imageType.includes('svg')) {
      fabric.loadSVGFromURL(imageData, (results: fabric.Object[]) => {
        const svg = fabric.util.groupSVGElements(results);
        const width = (svg as fabric.Object).get('width');
        const height = (svg as fabric.Object).get('height');
        const scale = this.calcLogoScaling(logoHeight, height!, width!, false);
        svg.set({
          scaleX: scale,
          scaleY: scale,
          top: top,
          left: left,
          selectable: false,
          hoverCursor: 'default',
          name: logoName,
          strokeWidth: 0,
        });
        this.insertLogo(svg);
      });
    } else {
      fabric.Image.fromURL(imageData, (image: fabric.Image) => {
        const scale = this.calcLogoScaling(
          logoHeight,
          image.get('height')!,
          image.get('width')!,
          false
        );
        image.set({
          scaleX: scale,
          scaleY: scale,
          top: top,
          left: left,
          selectable: false,
          hoverCursor: 'default',
          name: logoName,
          strokeWidth: 0,
        });
        this.insertLogo(image);
      });
    }
  }

  /**
   * add logo to canvas with additional conrtol button
   * @param logo
   */
  private insertLogo(logo: fabric.Image | fabric.Group | fabric.Object): void {
    const deleteGroup = new fabric.Group(
      [
        new fabric.Circle({
          height: this.canvasPadding / 2,
          width: this.canvasPadding / 2,
          radius: this.canvasPadding / 4,
          fill: 'black',
        }),
      ],
      {
        hoverCursor: 'pointer',
        name: 'deleteGroup',
        excludeFromExport: true,
      }
    );

    let left = (logo as fabric.Object).get('left')!;
    const top = (logo as fabric.Object).get('top')!;
    const width = logo.getScaledWidth();
    const height = logo.getScaledHeight();
    const scaleX = (logo as fabric.Object).get('scaleX')!;

    deleteGroup.set({
      left: left + width + 10,
      top: top + height / 2 - deleteGroup.get('height')! / 2,
    });
    deleteGroup.on('mouseup', () => {
      this._canvas?.remove(this.selectedBranding!.logo!);
      this.imageUploadModal!.fileName = 'Keine ausgewählt';
      const biggerWidth =
        this.selectedBranding!.institution!.get('width')! >
        this.selectedBranding!.name.get('width')!
          ? this.selectedBranding!.institution!.get('width')!
          : this.selectedBranding!.name.get('width')!;
      const uploadBox = this.createUploadBox(
        biggerWidth,
        this.selectedBranding!.logo!.get('left')!,
        this.selectedBranding!.logo!.get('top')!
      );
      this.selectedBranding!.logo = uploadBox;
      this._canvas?.add(this.selectedBranding!.logo);
      this.updateControlMarkerStatus('unchecked', 'logo');
      this.isEverythingValid();
      setTimeout(() => {
        this.adjustBrandingPositions();
        this.adjustBrandingPositions(true);
        this.selectedBranding!.institution!.setCoords();
        this.selectedBranding!.name.setCoords();
        this.selectedBranding!.logo!.setCoords();
        this.selectedBranding!.logoInvalid!.setCoords();
        this._canvas?.renderAll();
      }, 50);
    });
    const logoGroup = new fabric.Group([logo, deleteGroup], {
      selectable: false,
      subTargetCheck: true,
      hoverCursor: 'default',
      name: 'logoGroup',
      width: width + 10 + deleteGroup.get('width')!,
      strokeWidth: 0,
      fill: '#000000',
      stroke: '#000000',
    });

    if (top - height > 0) {
      logoGroup.set({
        top:
          this._canvas?.getHeight()! -
          this.canvasPadding -
          logoGroup.getScaledHeight(),
      });
      logoGroup.setCoords();
    }

    left = logoGroup.get('width')! / -2;
    (logo as fabric.Object).set({
      left: left,
      // top: top,
    });
    deleteGroup.set({
      left: left + width + 10,
    });
    deleteGroup.setCoords();

    fabric.loadSVGFromURL(
      '../assets/img/icn-trash.svg',
      (results: fabric.Object[], options: any) => {
        const deleteIcon = fabric.util.groupSVGElements(results);
        let iconHeight = (deleteIcon as fabric.Object).get('height');
        let iconWidth = (deleteIcon as fabric.Object).get('width');
        deleteGroup.add(deleteIcon);
        deleteIcon.bringToFront();
        const scale = iconHeight! / ((10 / 62) * this.canvasPadding);
        deleteIcon.scale(scale);
        deleteIcon.set({
          left: -(iconWidth! * scale) / 2,
          top: -(iconHeight! * scale) / 2,
        });

        this._canvas?.remove(this.selectedBranding!.logo!);
        this.selectedBranding!.logo = logoGroup;
        this._canvas?.add(this.selectedBranding!.logo);
        this.updateControlMarkerStatus('checked', 'logo');
        this.isEverythingValid();
      }
    );
    // const canvasExportService = new CanvasExportService(this._canvas!);

    // canvasExportService.changeCanvasColors([logoGroup], 'black', 'white');
  }

  /**
   * scale logo to fit canvas
   * @param targetHeight
   * @param currentHeight
   * @param currentWidth
   * @returns
   */
  private calcLogoScaling(
    targetHeight: number,
    currentHeight: number,
    currentWidth: number,
    isUploadBox: boolean
  ): number {
    let scale: number = targetHeight / currentHeight;

    if (!isUploadBox) {
      if (currentWidth * scale > this.wordBrandWidth) {
        scale = this.wordBrandWidth / currentWidth;
      }
    }

    return scale;
  }

  /**
   * remove the branding
   */
  public removeBranding(): void {
    if (this.selectedBranding) {
      if (this.selectedBranding.institution) {
        this._canvas?.remove(this.selectedBranding.institution);
      }
      if (this.selectedBranding.name) {
        this._canvas?.remove(this.selectedBranding.name);
      }
      if (this.selectedBranding.logo) {
        this._canvas?.remove(this.selectedBranding.logo);
      }
      this._canvas?.remove(this.selectedBranding.divider);
      this._canvas?.remove(...this.selectedBranding.checkmarks);
      this._canvas?.remove(
        ...this._canvas
          ?.getObjects()
          .filter((x) => x.get('name') === 'visibilityIconGroup')
      );

      this.brandingAdded.emit('standard');
    }
  }

  /**
   * add an intern or extern branding
   * @param type
   */
  public addBranding(type: 'intern' | 'extern'): void {
    if (this.logoType != 'Bild-Marke') {
      this.logoType = 'Bild-Marke';
      this.updateLogoType({ value: this.logoType });
    }

    if (this.selectedBranding) {
      this.removeBranding();
    }

    this.brandingAdded.emit(type);

    const xCoord =
      this.canvasPadding +
      this.letterWidth * 4 +
      this.letterSpacing * 3 +
      (2 / 3) * this.letterWidth;

    // Divider
    // const divider = new fabric.Line(
    //   [
    //     xCoord,
    //     this.canvasPadding,
    //     xCoord,
    //     this.canvasHeight - this.canvasPadding,
    //   ],
    //   {
    //     stroke: '#060605',
    //     strokeWidth: 1,
    //     hasControls: false,
    //     selectable: false,
    //     hoverCursor: 'default',
    //   }
    // );
    const divider = new fabric.Rect({
      left: xCoord,
      top: this.canvasPadding,
      height: this.canvasHeight - 2 * this.canvasPadding,
      width: 1,
      strokeWidth: 0,
      fill: '#060605',
      hasControls: false,
      selectable: false,
      hoverCursor: 'default',
    });

    // Texts
    const textOptions: ITextOptions = {
      //backgroundColor: '#ff0000',
      fontSize: (30 / 62) * this.canvasPadding,
      lineHeight: (37 / 62) * this.canvasPadding,
      fontFamily: 'RedHatText',
      includeDefaultValues: false,
      left: xCoord + this.letterWidth * (2 / 3) + 1,
      hasControls: false,
      name: 'branding',
      lockMovementY: true,
      lockMovementX: true,
      hoverCursor: 'text',
      fill: '#006B6B',
    };

    textOptions.lineHeight = textOptions.lineHeight! / textOptions.fontSize!;

    let institutionText: string;
    if (type === 'intern') {
      institutionText = 'Einrichtung / Fachbereich';
    } else {
      institutionText = 'In Kooperation mit';
    }
    var that = this;

    const institutionName = new fabric.Textbox(institutionText, textOptions);
    institutionName.set({
      top: this.grid![0] - 6,
      fontWeight: 500,
      name: 'institutionName',
      editingBorderColor: '#A8ECCA',
      borderColor: '#A8ECCA',
      strokeWidth: 0,
      charSpacing: -9,
      drawBorders: function (ctx, styleOverride) {
        var height = this.height || 0;
        var w = 300,
          h = 50,
          x = -300 / 2,
          y = -height / 2;

        ctx.beginPath();
        ctx.moveTo(-325, y);
        ctx.lineTo(325, y);
        ctx.closePath();
        var stroke = ctx.strokeStyle;
        ctx.strokeStyle = '#A8ECCA';
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(-325, y + height);
        ctx.lineTo(325, y + height);
        ctx.closePath();
        ctx.strokeStyle = '#A8ECCA';
        ctx.stroke();

        ctx.strokeStyle = stroke;
        return institutionName;
      },
      onInput: function (e) {
        // if (this._textLines!.length > 2) {
        //   return;
        // }
        var nextText = this.hiddenTextarea!.value,
          charCount = this._text!.length,
          nextCharCount = nextText.length,
          removedText,
          insertedText,
          charDiff = nextCharCount - charCount,
          selectionStart = this.selectionStart,
          selectionEnd = this.selectionEnd,
          selection = selectionStart !== selectionEnd,
          copiedStyle,
          removeFrom,
          removeTo;
        var textareaSelection = this.fromStringToGraphemeSelection!(
          this.hiddenTextarea!.selectionStart,
          this.hiddenTextarea!.selectionEnd,
          this.hiddenTextarea!.value
        );

        var linesToAdd = this.hiddenTextarea!.value.split('\n').length;

        insertedText = nextText.slice(
          textareaSelection.selectionEnd - charDiff,
          textareaSelection.selectionEnd
        );

        var allowedLinesCounter = 2;
        const customLogo = that._canvas
          ?.getObjects()!
          .filter((x) => x.name && x.name!.includes('logoGroup'))!;
        if (customLogo.length) {
          allowedLinesCounter = 1;
        }

        if (charCount - nextCharCount < 0) {
          var currentLineWidth =
            this.__lineWidths![this.get2DCursorLocation!().lineIndex];
          if (currentLineWidth > that.wordBrandWidth) {
            var lastWordRegex = new RegExp(/\s[\d\w]+\s?$/);
            var lastWordPosition = lastWordRegex.exec(
              this.hiddenTextarea!.value
            );
            if (linesToAdd + 1 > allowedLinesCounter) {
              this.hiddenTextarea!.value = this.text || '';
              return;
            }
            this.hiddenTextarea!.value =
              this.hiddenTextarea!.value.substring(0, lastWordPosition!.index) +
              '\n' +
              this.hiddenTextarea!.value.substring(lastWordPosition!.index + 1);
          }
        }

        if (
          (insertedText.charCodeAt(0) === 10 && this.text?.includes('\n')) ||
          linesToAdd > allowedLinesCounter ||
          that.contactNameLinesCounter > 4
        ) {
          this.hiddenTextarea!.value = this.text || '';
          return;
        }
        that.institutionNameLinesCounter =
          (this.hiddenTextarea!.value.match(new RegExp('\n', 'g')) || [])
            .length + 1;

        if (
          that.contactNameLinesCounter + that.institutionNameLinesCounter >
          3
        ) {
          const customLogo = that._canvas
            ?.getObjects()!
            .filter((x) => x.name && x.name!.includes('logoGroup'))!;

          //that._canvas?.remove(that.logo);
          that._canvas?.remove(that.selectedBranding!.logo!);

          const uploadBoxInvalid = that._canvas
            ?.getObjects()!
            .filter((x) => x.name && x.name!.includes('uploadInvalidBox'))!;
          if (!uploadBoxInvalid.length && !customLogo.length) {
            that._canvas?.add(that.logoInvalid);
          }
        } else {
          const customLogo = that._canvas
            ?.getObjects()!
            .filter((x) => x.name && x.name!.includes('logoGroup'))!;
          const uploadBox = that._canvas
            ?.getObjects()!
            .filter((x) => x.name && x.name!.includes('uploadBox'))!;
          if (!uploadBox.length && !customLogo.length) {
            // that._canvas?.add(that.logo);
            // that._canvas?.remove(that.logoInvalid);
            that._canvas?.add(that.selectedBranding!.logo!);
            that._canvas?.remove(that.selectedBranding!.logoInvalid!);
          }
        }

        this.updateFromTextArea!();

        that.isEverythingValid();
        if (this.text!.length > 0) {
          that.updateControlMarkerStatus('checked', 'institutionName');
        } else {
          that.updateControlMarkerStatus('unchecked', 'institutionName');
          that.logoValid.patchValue(false);
        }

        this.fire!('changed');
        if (this.canvas) {
          this.canvas.fire('text:changed', { target: this });
          this.canvas.requestRenderAll();
        }
        return true;
        // Call parent class method
      },
    });
    const contactNameContent = 'Ansprechpartner:in / Abk. Einrichtung / Abk. FB'
    const contactName = new fabric.Textbox(contactNameContent, textOptions);
    contactName.set({
      top: this.grid![0] - 5 + (41 / 62) * this.canvasPadding,
      fontWeight: 400,
      name: 'name',
      strokeWidth: 0,
      editingBorderColor: '#A8ECCA',
      charSpacing: -1,
      drawBorders: function (ctx, styleOverride) {
        var height = this.height || 0;
        var w = 300,
          h = 50,
          x = -300 / 2,
          y = -height / 2;

        ctx.beginPath();
        ctx.moveTo(-325, y);
        ctx.lineTo(325, y);
        ctx.closePath();
        var stroke = ctx.strokeStyle;
        ctx.strokeStyle = '#A8ECCA';
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(-325, y + height);
        ctx.lineTo(325, y + height);
        ctx.closePath();
        ctx.strokeStyle = '#A8ECCA';
        ctx.stroke();

        ctx.strokeStyle = stroke;
        return contactName;
      },
      onInput: function (e) {
        // if (this._textLines!.length > 2) {
        //   return;
        // }
        var nextText = this.hiddenTextarea!.value,
          charCount = this._text!.length,
          nextCharCount = nextText.length,
          removedText,
          insertedText,
          charDiff = nextCharCount - charCount,
          selectionStart = this.selectionStart,
          selectionEnd = this.selectionEnd,
          selection = selectionStart !== selectionEnd,
          copiedStyle,
          removeFrom,
          removeTo;
        var textareaSelection = this.fromStringToGraphemeSelection!(
          this.hiddenTextarea!.selectionStart,
          this.hiddenTextarea!.selectionEnd,
          this.hiddenTextarea!.value
        );

        var linesToAdd = this.hiddenTextarea!.value.split('\n').length;

        insertedText = nextText.slice(
          textareaSelection.selectionEnd - charDiff,
          textareaSelection.selectionEnd
        );

        var allowedLinesCounter = that.institutionNameLinesCounter == 1 ? 5 : 4;

        const customLogo = that._canvas
          ?.getObjects()!
          .filter((x) => x.name && x.name!.includes('logoGroup'))!;
        if (customLogo.length) {
          allowedLinesCounter = that.institutionNameLinesCounter == 1 ? 2 : 1;
        }

        if (charCount - nextCharCount < 0) {
          var currentLineWidth =
            this.__lineWidths![this.get2DCursorLocation!().lineIndex];
          if (currentLineWidth > that.wordBrandWidth) {
            var lastWordRegex = new RegExp(/\s[\d\w]+\s?$/);
            var lastWordPosition = lastWordRegex.exec(
              this.hiddenTextarea!.value
            );
            if (linesToAdd + 1 > allowedLinesCounter) {
              this.hiddenTextarea!.value = this.text || '';
              return;
            }
            this.hiddenTextarea!.value =
              this.hiddenTextarea!.value.substring(0, lastWordPosition!.index) +
              '\n' +
              this.hiddenTextarea!.value.substring(lastWordPosition!.index + 1);
          }
        }

        if (
          (insertedText.charCodeAt(0) === 10 &&
            (this.text?.match(new RegExp('\n', 'g')) || []).length + 1 >
              allowedLinesCounter) ||
          linesToAdd > allowedLinesCounter
        ) {
          this.hiddenTextarea!.value = this.text || '';
          return;
        }
        that.contactNameLinesCounter =
          (this.hiddenTextarea!.value.match(new RegExp('\n', 'g')) || [])
            .length + 1;

        if (
          that.contactNameLinesCounter + that.institutionNameLinesCounter >
          3
        ) {
          const customLogo = that._canvas
            ?.getObjects()!
            .filter((x) => x.name && x.name!.includes('logoGroup'))!;

          //that._canvas?.remove(that.logo);

          that._canvas?.remove(that.selectedBranding!.logo!);

          const uploadBoxInvalid = that._canvas
            ?.getObjects()!
            .filter((x) => x.name && x.name!.includes('uploadInvalidBox'))!;
          if (!uploadBoxInvalid.length && !customLogo.length) {
            that._canvas?.add(that.logoInvalid);
          }
        } else {
          const customLogo = that._canvas
            ?.getObjects()!
            .filter((x) => x.name && x.name!.includes('logoGroup'))!;

          const uploadBox = that._canvas
            ?.getObjects()!
            .filter((x) => x.name && x.name!.includes('uploadBox'))!;
          if (!uploadBox.length && !customLogo.length) {
            // that._canvas?.add(that.logo);
            // that._canvas?.remove(that.logoInvalid);

            that._canvas?.add(that.selectedBranding!.logo!);
            that._canvas?.remove(that.selectedBranding!.logoInvalid!);
          }
        }
        this.updateFromTextArea!();

        that.isEverythingValid();
        if (this.text!.length > 0) {
          that.updateControlMarkerStatus('checked', 'name');
        } else {
          that.updateControlMarkerStatus('unchecked', 'name');
          that.logoValid.patchValue(false);
        }

        this.fire!('changed');
        if (this.canvas) {
          this.canvas.fire('text:changed', { target: this });
          this.canvas.requestRenderAll();
        }

        // Call parent class method
        return true;
      },
    });

    this.contactNameLinesCounter =
      (contactName.get('text')!.match(new RegExp('\n', 'g')) || []).length + 1;

    this.institutionNameLinesCounter =
      (institutionName.get('text')!.match(new RegExp('\n', 'g')) || []).length +
      1;

    // add event listeners
    if (type === 'extern') {
      institutionName.set({
        selectable: true,
        editable: true,
        hoverCursor: 'normal',
        fill: 'black',
        strokeWidth: 0,
      });
      // const visibilityIconGroup = this.createVisibilityLogoGroup(
      //   institutionName.get('left')! + (268 / 62) * this.canvasPadding
      // );
      // institutionName.on('mousedown', (event: IEvent) => {
      //   let exclude = institutionName.get('excludeFromExport')! ? false : true;
      //   institutionName.set({
      //     excludeFromExport: exclude,
      //   });
      //   for (let icon of visibilityIconGroup.getObjects()) {
      //     if (icon.get('name') === 'visible') {
      //       icon.set({
      //         opacity: Number(!exclude),
      //       });
      //     } else {
      //       icon.set({
      //         opacity: Number(exclude),
      //       });
      //     }
      //   }
      // });
    } else {
      institutionName.on('selected', (event: any) => {
        this.enterEditing(
          this._canvas!.getActiveObject() as fabric.IText,
          institutionText
        );
      });
      institutionName.on('editing:exited', (event: fabric.IEvent) => {
        this.exitEditing(
          this._canvas!.getActiveObject() as fabric.IText,
          institutionText
        );
      });
    }
    contactName.on('selected', (event: fabric.IEvent) => {
      this.enterEditing(
        this._canvas!.getActiveObject() as fabric.IText,
        contactNameContent
      );
    });
    contactName.on('editing:exited', (event: fabric.IEvent) => {
      this.exitEditing(
        this._canvas!.getActiveObject() as fabric.IText,
        contactNameContent
      );
    });

    const biggerWidth =
      this.canvasWidth - this.imageBrandWidth - (10 / 3) * this.canvasPadding;

    // Logo
    this.logo = this.createUploadBox(
      biggerWidth,
      xCoord + (this.canvasPadding * 2) / 3,
      this.grid![1]
    );

    // Logo
    this.logoInvalid = this.createUploadBox(
      biggerWidth,
      xCoord + (this.canvasPadding * 2) / 3,
      this.grid![1],
      'logoInvalid'
    );

    // Create checkmarks
    let checkmarks: fabric.Group[] = [];
    const checkmarkLeft = this.canvasWidth - this.canvasPadding + 10;
    if (type === 'intern') {
      checkmarks.push(
        this.addControlMarker('institutionName', checkmarkLeft, this.grid![0])
      );
    }
    checkmarks.push(
      this.addControlMarker(
        'name',
        checkmarkLeft,
        this.grid![0] + (42 / 62) * this.canvasPadding
      )
    );
    checkmarks.push(
      this.addControlMarker(
        'logo',
        checkmarkLeft,
        this.grid![2] - (24 / 62) * this.canvasPadding
      )
    );

    this.selectedBranding = {
      institution: institutionName,
      name: contactName,
      logo: this.logo,
      logoInvalid: this.logoInvalid,
      groupName: 'branding-' + type,
      divider: divider,
      width: biggerWidth + (this.canvasPadding * 4) / 3,
      checkmarks: checkmarks,
    };

    institutionName.set('width', biggerWidth);
    contactName.set('width', biggerWidth);

    this._canvas?.add(institutionName, contactName, divider, this.logo);
    this.isEverythingValid();
  }

  private createVisibilityLogoGroup(left: number): fabric.Group {
    const visibilityIconGroup = new fabric.Group([], {
      name: 'visibilityIconGroup',
      left: left,
      top: this.canvasPadding,
      height: 20,
      width: 20,
      excludeFromExport: true,
      selectable: false,
      hoverCursor: 'default',
      evented: false,
    });

    let index = -1;
    for (let visibility of ['visible', 'invisible']) {
      fabric.loadSVGFromURL(
        '../assets/img/icn-' + visibility + '.svg',
        (result, options) => {
          const svg = fabric.util.groupSVGElements(result);
          visibilityIconGroup.add(svg);
          let height, width;
          if (this.typeGuardGroup(svg)) {
            height = svg.get('height');
            width = svg.get('width');
          } else {
            height = svg.get('height');
            width = svg.get('width');
          }
          svg.set({
            top: height! / -2 + index++,
            left: width! / -2 + index,
            name: visibility,
            opacity: visibility === 'visible' ? 1 : 0,
          });
          this._canvas!.renderAll();
        }
      );
    }

    this._canvas!.add(visibilityIconGroup);

    return visibilityIconGroup;
  }

  private enterEditing(text: fabric.IText, placeholder: string): void {
    if (text.get('text') === placeholder) {
      text.set({
        text: '',
        fill: 'black',
      });
    }
    text.enterEditing();
  }

  private exitEditing(text: fabric.IText, placeholder: string): void {
    if (text.get('text') === '' || text.get('text') === placeholder) {
      text.set({
        text: placeholder,
        fill: '#006B6B',
      });
      this.updateControlMarkerStatus('unchecked', text.get('name')!);
    } else {
      this.updateControlMarkerStatus('checked', text.get('name')!);
    }
    this.isEverythingValid();
  }

  /**
   * create a box that triggers the upload modal on click
   * @param biggerWidth
   * @param left
   * @param top
   * @returns
   */
  private createUploadBox(
    biggerWidth: number,
    left: number,
    top: number,
    logoInvalid = ''
  ): fabric.Group {
    let scalingNeeded = false;
    // rounding errors might mess with this, so we check only for 6 decimals
    if (
      Math.floor(top * 1000000) / 1000000 !==
      Math.floor(this.grid![1] * 1000000) / 1000000
    ) {
      scalingNeeded = true;
    }
    const logo = new fabric.Group([], {
      selectable: false,
      width: biggerWidth,
      height: (this.canvasPadding * 7) / 3,
      left: left,
      top: top,
      hoverCursor: !logoInvalid ? 'pointer' : 'normal',
      name: !logoInvalid ? 'uploadBox' : 'uploadInvalidBox',
      excludeFromExport: true,
    });
    if (!logoInvalid) {
      logo.on('mouseup', (event: IEvent) => {
        this.imageUploadModal!.open();
      });
    }

    if (!logoInvalid) {
      var background = new fabric.Group(
        [
          new fabric.Image(this.backgroundImage.nativeElement, {
            height: logo.get('height'),
          }),
        ],
        {
          name: 'background',
        }
      );
    } else {
      var background = new fabric.Group(
        [
          new fabric.Image(this.backgroundErrorImage.nativeElement, {
            height: logo.get('height'),
          }),
        ],
        {
          name: 'background',
        }
      );
    }
    let width = background.get('width')!,
      firstImage = background.getObjects()[0],
      leftStart = firstImage.get('left')!,
      topStart = firstImage.get('top')!,
      combinedWidth = width,
      j = 1;
    for (let i = 0; i < 2; i++) {
      combinedWidth = width - i * width;
      topStart = topStart + i * this.imageDefaultHeight;
      j = 1 - i;
      while (combinedWidth < biggerWidth) {
        if (!logoInvalid) {
          var object = new fabric.Image(this.backgroundImage.nativeElement);
        } else {
          var object = new fabric.Image(
            this.backgroundErrorImage.nativeElement
          );
        }
        if (!this.imageDefaultHeight) {
          this.imageDefaultHeight = object.get('height')!;
        }
        background.add(object);
        combinedWidth += width;
        if (combinedWidth > biggerWidth) {
          object.set({
            left: leftStart + width * j++,
            width: width - (combinedWidth - biggerWidth),
          });
        } else {
          object.set('left', leftStart + width * j++);
        }
        if (i === 1) {
          object.set({
            height: logo.get('height')! - this.imageDefaultHeight,
          });
        }
        object.set({
          top: topStart,
        });
      }
    }

    const uploadText = new fabric.Text('IHR LOGO', {
      fontFamily: 'RedHatText',
      fontSize: Math.ceil(this.canvasPadding * (11 / 63)),
      fontWeight: 800,
      fill: 'white',
      charSpacing: 110,
    });
    const uploadIcon = new fabric.Group(
      [
        new fabric.Rect({
          height: (40 / 63) * this.canvasPadding,
          width: this.canvasPadding * (160 / 63) - 15,
          rx: 5,
          ry: 5,
          fill: 'black',
        }),
      ],
      { originX: 'left', originY: 'top' }
    );
    fabric.loadSVGFromURL(
      '../assets/img/icn-plus.svg',
      (results: fabric.Object[]) => {
        const plus = fabric.util.groupSVGElements(results);
        uploadIcon.addWithUpdate(plus);
        let left, top, height;
        if (this.typeGuardGroup(plus)) {
          left = plus.get('left');
          top = plus.get('top');
          height = plus.get('height');
        } else {
          left = plus.get('left');
          top = plus.get('top');
          height = plus.get('height');
        }
        const buttonHeight = uploadIcon.getObjects('rect')![0].get('height')!;
        plus.set({
          fill: '#fff',
          backgroundColor: '#fff',
          top: top! + (buttonHeight - height!) / 2,
          left: left! + (15 / 63) * this.canvasPadding,
        });
        plus.bringToFront();
        const uploadGroup = new fabric.Group([uploadText, uploadIcon], {
          originX: 'center',
          originY: 'center',
          name: 'uploadGroup',
        });
        uploadText.bringToFront();
        uploadText.set({
          top:
            uploadText.get('top')! +
            (buttonHeight - uploadText.get('height')!) / 2,
          left: uploadText.get('left')! + (56 / 63) * this.canvasPadding - 5,
        });

        if (!logoInvalid) {
          logo.add(background, uploadGroup);
        } else {
          logo.add(background);
        }
        uploadGroup.set({
          left: 0,
          top: 0,
        });
        background.set({
          left: -(logo.get('width')! / 2),
          top: -(logo.get('height')! / 2),
        });

        // scaling if height is only one row
        if (scalingNeeded) {
          const scale = this.canvasPadding / ((this.canvasPadding * 7) / 3);

          logo.set({
            scaleY: scale,
          });

          background.set({
            scaleY: 1 / scale,
          });
          let backgroundImages = (background as fabric.Group).getObjects();
          let left = backgroundImages[0].get('left')!;
          this.changeBackgroundOpacity(
            backgroundImages,
            left,
            this.canvasPadding,
            0
          );

          uploadGroup.set({
            scaleY: 1 / scale,
          });
        }

        this._canvas?.renderAll();
      }
    );

    return logo;
  }

  public updateLogoType(change: { value: string }) {
    const letterT = this._canvas
      ?.getObjects()
      .filter(
        (object: fabric.Object) =>
          object.get('name') && object.get('name') === 'T'
      )[0];
    if (change.value == 'Wort-Bild-Marke') {
      letterT?.set({
        lockMovementY: true,
        selectable: false,
        hoverCursor: 'default',
        top: this.grid![1],
      });
      this.gridPositions.T.y = 1;
      this.addBrandText();
    } else {
      letterT?.set({
        lockMovementY: false,
        selectable: true,
        hoverCursor: 'move',
      });
      this.removeBrandText();
    }
  }

  private removeBrandText(): void {
    // const texts = this._canvas
    //   ?.getObjects('text')!
    //   .filter((x) => x.name && x.name!.includes('brandText'))!;
    // this._canvas?.remove(texts[0], texts[1]);
    const logo = this._canvas
      ?.getObjects('logo')!
      .filter((x) => x.name && x.name!.includes('brandText'))!;
    this._canvas?.remove(logo[0]);
  }

  public addBrandText(): void {
    const xCoord =
      this.canvasPadding +
      this.letterWidth * 4 +
      this.letterSpacing * 3 +
      (1 / 3) * this.canvasPadding;

    const fontRedHatTextMedium = new FontFace(
      'RedHatText',
      'url(../../assets/font/RedHatText/RedHatText-Medium.woff2)',
      { weight: '500' }
    );
    const fontRedHatTextRegular = new FontFace(
      'RedHatText',
      'url(../../assets/font/RedHatText/RedHatText-Regular.woff2)',
      { weight: '400' }
    );
    const fontRedHatTextLight = new FontFace(
      'RedHatText',
      'url(../../assets/font/RedHatText/RedHatText-Light.woff2)',
      { weight: '100' }
    );
    document.fonts.add(fontRedHatTextMedium);
    document.fonts.add(fontRedHatTextRegular);
    document.fonts.add(fontRedHatTextLight);

    const fontLoaders: Promise<FontFace>[] = [
      fontRedHatTextMedium.load(),
      fontRedHatTextRegular.load(),
      fontRedHatTextLight.load(),
    ];

    Promise.all(fontLoaders).then(
      () => {
        // const brandName = new fabric.Text(
        //   'Rheinland-Pfälzische\nTechnische Universität test',
        //   {
        //     top: this.grid![1] - 7,
        //     left: xCoord,
        //     fontFamily: 'RedHatText',
        //     fontSize: this.canvasPadding / 2,
        //     fontWeight: 600,
        //     width: 500 - this.canvasPadding,
        //     selectable: false,
        //     hoverCursor: 'default',
        //     name: 'brandText1',
        //   }
        // );
        // this._canvas?.add(brandName);

        // const brandLocation = new fabric.Text('Kaiserslautern\nLandau', {
        //   top: this.grid![2] - 7,
        //   left: xCoord,
        //   fontFamily: 'RedHatText',
        //   fontSize: this.canvasPadding / 2,
        //   fontWeight: 100,
        //   width: 500 - this.canvasPadding,
        //   selectable: false,
        //   hoverCursor: 'default',
        //   name: 'brandText2',
        // });
        // this._canvas?.add(brandLocation);

        const uploadBoxInvalid = this._canvas
          ?.getObjects()!
          .filter((x) => x.name && x.name!.includes('uploadInvalidBox'))!;
        if (uploadBoxInvalid.length) {
          this._canvas?.remove(this.logoInvalid);
        }

        fabric.loadSVGFromURL(
          '../assets/img/Logo-RPTU-Wortmarke.svg',
          (results: fabric.Object[]) => {
            const logo = fabric.util.groupSVGElements(results);

            logo.set({
              left: xCoord,
              top: this.grid![1] - 2,
              selectable: false,
              evented: false,
              name: 'brandText1',
              type: 'logo',
              // scaleX
              // scaleY
              strokeWidth: 0,
            });

            // if (logo && logo.group?._objects) {
            //   for (var i = 0; i < logo.group?._objects.length; i++) {
            //     logo.group?._objects[i].set({});
            //   }
            // }

            this.wordBrandWidth =
              logo.getScaledWidth() + 2 * this.canvasPadding;

            this._canvas?.add(logo);
            this.isEverythingValid();
          }
        );
      },
      (err: any) => {
        console.error(err);
      }
    );
  }

  private moveInBoundaries(movingObject?: fabric.Object): void {
    if (movingObject != undefined) {
      let objectBoundaries = {
        top: movingObject.get('top')!,
        right:
          movingObject.get('left')! +
          movingObject.get('width')! * movingObject.get('scaleX')!,
        bottom:
          movingObject.get('top')! +
          movingObject.get('height')! * movingObject.get('scaleY')!,
        left: movingObject.get('left')!,
      };
      this.moveInHorizontalRestriction(movingObject, objectBoundaries);
      this.moveInVerticalRestriction(movingObject, objectBoundaries);
    }
  }

  private moveInVerticalRestriction(
    movingObject: fabric.Object,
    objectBoundaries: any
  ): void {
    this.canvasHeight = this._canvas?.getHeight()!;

    if (objectBoundaries.top < this.breakpoints!.top) {
      movingObject.set('top', this.grid![0]);
      if (
        movingObject.get('name') &&
        Object.keys(this.gridPositions).includes(movingObject.get('name')!)
      ) {
        this.gridPositions[
          movingObject.get('name')! as 'R' | 'P' | 'T' | 'U'
        ].y = 0;
      }
    }
    if (
      objectBoundaries.top > this.breakpoints!.top &&
      objectBoundaries.top < this.breakpoints!.bottom
    ) {
      movingObject.set('top', this.grid![1]);
      if (
        movingObject.get('name') &&
        Object.keys(this.gridPositions).includes(movingObject.get('name')!)
      ) {
        this.gridPositions[
          movingObject.get('name')! as 'R' | 'P' | 'T' | 'U'
        ].y = 1;
      }
    }
    if (objectBoundaries.top > this.breakpoints!.bottom) {
      movingObject.set('top', this.grid![2]);
      if (
        movingObject.get('name') &&
        Object.keys(this.gridPositions).includes(movingObject.get('name')!)
      ) {
        this.gridPositions[
          movingObject.get('name')! as 'R' | 'P' | 'T' | 'U'
        ].y = 2;
      }
    }
  }

  private moveInHorizontalRestriction(
    movingObject: fabric.Object,
    objectBoundaries: any
  ): void {
    if (objectBoundaries.left < this.movementArea!.left) {
      movingObject.set('left', this.movementArea!.left);
    }
    if (objectBoundaries.right > this.movementArea!.right) {
      movingObject.set(
        'left',
        this.movementArea!.right -
          movingObject.get('width')! * movingObject.get('scaleX')!
      );
    }
  }

  private setMovementArea(): void {
    this.movementArea = {
      left: 0 + this.canvasPadding,
      right: this.canvasWidth - this.canvasPadding,
    };
  }

  private typeGuardImage(object?: fabric.Object): object is fabric.Image {
    if ((object as fabric.Image).filters) {
      return true;
    }
    return false;
  }

  private typeGuardGroup(object?: fabric.Object): object is fabric.Group {
    if ((object as fabric.Group)._objects) {
      return true;
    }
    return false;
  }
}
