import {
  OnInit,
  AfterViewInit,
  Component,
  EventEmitter,
  Output,
  ViewChild,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { EditorComponent } from '@app/theme/components/editor/editor.component';
import {
  NbStepChangeEvent,
  NbStepComponent,
  NbStepperComponent,
} from '@nebular/theme';
import { LogoPreviewComponent } from '@app/theme/components/logo-preview/logo-preview.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { oneCheckboxChecked } from '@app/lib/validator/OneCheckboxCheckedValidator';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FileCreationService } from '@app/lib/service/fileCreationService';
import { CanvasExportService } from '@app/lib/service/canvasExportService';
import { fileCreationRequest } from '@app/lib/model/fileCreationRequest';
import { Router } from '@angular/router';
import { ImageUploadModalComponent } from '@app/theme/components/image-upload-modal/image-upload-modal.component';
import { needTrue } from '@app/lib/validator/NeedTrue';

@Component({
  selector: 'app-generator',
  templateUrl: './generator.component.html',
  styleUrls: ['./generator.component.scss'],
})
export class GeneratorComponent implements OnInit, AfterViewInit {
  headerTextCollection = {
    standard:
      '<p>Wählen Sie die Konfiguration der Bild-Marke nach Ihren Wünschen aus. Keine Sorge, der Logo-Generator bietet Ihnen nur die Möglichkeiten an, die zugelassen sind (grün). Ausgeschlossene Varianten (rot) können Sie nicht wählen.</p><p>Sie wollen anstelle der Wort-Marke ein weiteres Logo platzieren? Dann klicken Sie bitte auf „Logo kombinieren“ (unten rechts).</p>',
    intern:
      '<p>Eine Gliederung der RPTU kann der Bild-Marke in Textform und auch mit eigenem Logo hinzugefügt werden. Der Logo-Generator unterstützt Sie dabei, alle Regeln einzuhalten. Bitte beachten Sie die Hinweise für die Verwendung weiterer Logos.</p>',
    extern:
      '<p>Ein externer Partner kann der Bild-Marke in Textform und auch mit eigenem Logo hinzugefügt werden. Der Logo-Generator unterstützt Sie dabei, alle Regeln einzuhalten. Bitte beachten Sie die Hinweise für die Verwendung weiterer Logos.</p>',
    color:
      '<p>In welcher Farbe wollen Sie Ihr Logo gestalten? Bedenken Sie, ob Ihr Logo auf einem hellen oder dunklen Hintergrund platziert werden soll. Der Logo-Generator bietet Ihnen alle passenden Farben an.<br />Sie können Ihr Logo auch in verschiedenen Farben gestalten. Bitte starten Sie den Logo-Generator dazu einfach noch einmal neu, sobald Sie Ihr Logo gestaltet haben.</p>',
    form: '<p>Fast geschafft! Damit der Logo-Generator Ihr Logo auch in der von Ihnen gewünschten Form an Sie verschicken kann, fehlen nur noch wenige Angaben.</p>',
  };

  headerData = {
    headline: 'Logo-Generator der RPTU',
    bodytext: this.headerTextCollection.standard,
  };

  @ViewChild(EditorComponent) editor!: EditorComponent;
  @ViewChild(ImageUploadModalComponent)
  imageUploadModal!: ImageUploadModalComponent;
  @ViewChild(LogoPreviewComponent) logoPreview!: LogoPreviewComponent;
  @ViewChild('sizeDropdown') sizeDropdown!: NgSelectComponent;
  @ViewChild(NbStepperComponent) stepper!: NbStepperComponent;
  @ViewChildren(NbStepComponent) steps!: QueryList<NbStepComponent>;
  @Output('loading') loading: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  newStepHash = 'step1';

  colorSelected: boolean = false;
  selectedTextColor?: string;
  selectedBackgroundColor?: string;
  previewGenerated: FormControl = new FormControl(false, needTrue);
  preview?: string;

  formStepShown = false;
  formValidated = false;

  fileCreationForm = new FormGroup({
    colorSpace: new FormControl(undefined, Validators.required),
    //size: new FormControl(undefined, Validators.required),
    fileFormats: new FormGroup(
      {
        jpg: new FormControl({ value: false, disabled: true }),
        png: new FormControl({ value: false, disabled: true }),
        eps: new FormControl({ value: false, disabled: true }),
        pdf: new FormControl({ value: false, disabled: true }),
        svg: new FormControl({ value: false, disabled: true }),
      },
      {
        validators: [oneCheckboxChecked],
      }
    ),
    contactData: new FormGroup({
      firstName: new FormControl(undefined, Validators.required),
      lastName: new FormControl(undefined, Validators.required),
      email: new FormControl(undefined, [
        Validators.required,
        Validators.email,
      ]),
    }),
    usage: new FormControl(undefined, Validators.required),
  });

  sizeSelectOptions: any = {
    digital: [{ width: '1069px', height: '350px' }],
    print: [{ width: '106.9mm', height: '35mm' }],
  };

  usageSelectOptions = [{ value: 'INTERN' }, { value: 'EXTERN' }];

  logoType?: 'Organisation' | 'Partner';

  constructor(
    private fileCreationService: FileCreationService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    window.location.hash = 'step1';
  }

  public ngAfterViewInit(): void {
    this.fileCreationForm
      .get('colorSpace')!
      .valueChanges.subscribe((value: any) => {
        let formats = this.fileCreationForm.get('fileFormats')!;
        if (value === 'print') {
          formats.get('jpg')?.enable();
          formats.get('eps')?.enable();
          formats.get('png')?.disable();
          formats.get('png')?.patchValue(false);
          formats.get('pdf')?.disable();
          formats.get('pdf')?.patchValue(false);
          formats.get('svg')?.disable();
          formats.get('svg')?.patchValue(false);
        } else {
          formats.get('jpg')?.enable();
          formats.get('eps')?.enable();
          formats.get('png')?.enable();
          formats.get('pdf')?.enable();
          formats.get('svg')?.enable();
        }
        // if (value) {
        //   if (this.sizeDropdown.element.classList.contains('disabled')) {
        //     this.sizeDropdown.element.classList.remove('disabled');
        //   }
        // } else {
        //   this.sizeDropdown.element.classList.add('disabled');
        // }
      });
    setTimeout(() => {
      this.editor.imageUploadModal = this.imageUploadModal;
    });
    window.addEventListener('hashchange', (hashEvent) => {
      if ((hashEvent as HashChangeEvent).newURL !== this.newStepHash) {
        const newHash = (hashEvent as HashChangeEvent).newURL.split('#')[1];
        const newStepIndex = newHash ? Number(newHash.substring(4)) - 1 : 0;
        this.stepper.changeStep(this.steps.toArray()[newStepIndex]);
      }
    });
  }

  public handleStepChangeEvent(event: NbStepChangeEvent): void {
    this.changeCompletedStepIndex(event);
    this.newStepHash = 'step' + String(event.index + 1);
    window.location.hash = this.newStepHash;
  }

  private changeCompletedStepIndex(event: NbStepChangeEvent): void {
    const steps = document.querySelectorAll('nb-stepper .header .step');
    if (steps[event.index].classList.contains('completed')) {
      steps[event.index].classList.remove('completed');
    }
    if (event.index > event.previouslySelectedIndex) {
      steps[event.previouslySelectedIndex].classList.add('completed');
    }
    const canvasExportService = new CanvasExportService(this.editor._canvas!);
    if (event.index === 0) {
      canvasExportService.prepareCanvasForExport();
      const visibleObjects = canvasExportService.getVisibleCanvasObjects();
      canvasExportService.changeCanvasColors(visibleObjects, 'black', 'white');
      canvasExportService.restoreCanvasAfterExport();
      this.headerData.bodytext = this.headerTextCollection.standard;
    } else {
      if (this.colorSelected) {
        this.logoPreview.generatePreview(
          this.selectedTextColor!,
          this.selectedBackgroundColor!,
          this.editor!.wordBrandWidth + this.editor!.canvasPadding
        );
      }
    }
    if (event.index === 1) {
      this.headerData.bodytext = this.headerTextCollection.color;
    }
    if (event.index === 2) {
      this.headerData.bodytext = this.headerTextCollection.form;
      this.formStepShown = true;
    } else {
      this.formStepShown = false;
    }
    event.previouslySelectedStep.interacted = false;
    this.handleGeneratePreviewEvent();
  }

  public updateHeaderData(type: 'intern' | 'extern' | 'standard'): void {
    switch (type) {
      case 'standard':
        this.logoType = undefined;
        break;
      case 'intern':
        this.logoType = 'Organisation';
        break;
      case 'extern':
        this.logoType = 'Partner';
    }
    this.headerData.bodytext = this.headerTextCollection[type];
  }

  public updateSelectedColor(color: {
    textColor: string;
    backgroundColor: string;
  }): void {
    if (!this.colorSelected) {
      this.colorSelected = true;
    }
    this.selectedTextColor = color.textColor;
    this.selectedBackgroundColor = color.backgroundColor;
  }

  public handleGeneratePreviewEvent(): void {
    this.logoPreview.canvas = this.editor._canvas;
    this.preview = this.logoPreview.generatePreview(
      this.selectedTextColor!,
      this.selectedBackgroundColor!,
      this.editor!.wordBrandWidth + this.editor!.canvasPadding
    );
    this.logoPreview.backgroundColor = this.selectedBackgroundColor!;
    if (this.logoPreview.base64image) {
      this.previewGenerated.patchValue(true);
    }
  }

  public handleFileCreationFormSubmit(event: any): void {
    this.formValidated = true;
    if (this.fileCreationForm.status === 'VALID') {
      this.submitForm();
    }
  }

  private submitForm(): void {
    //const dimensions = this.size.value.split('x');
    // let width = dimensions[0],
    //   height = dimensions[1];
    let width = this.sizeSelectOptions[this.colorSpace.value][0].width;
    let height = this.sizeSelectOptions[this.colorSpace.value][0].height;
    const fileCreationRequest: fileCreationRequest = {
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      email: this.email.value,
      path: new Date().getTime().toString(),
      width: width,
      height: height,
      imageData: {
        type: [],
        colorSpace: 'digital',
        textColor: this.selectedTextColor,
      },
      usage: this.usage.value,
      timestamp: Math.floor(new Date().getTime() / 1000),
      logoType: this.logoType,
    };

    const canvasExportService = new CanvasExportService(this.editor._canvas!);
    canvasExportService.prepareCanvasForExport();
    const visibleObjects = canvasExportService.getVisibleCanvasObjects();
    if (
      canvasExportService.validateTextColor(this.selectedTextColor!) &&
      CanvasExportService.validateBackgroundColor(this.selectedBackgroundColor!)
    ) {
      canvasExportService.changeCanvasColors(
        visibleObjects,
        this.selectedTextColor,
        this.selectedBackgroundColor
      );
    }
    fileCreationRequest.imageData = canvasExportService.generateCanvasImageData(
      this.fileFormats,
      this.colorSpace,
      this.editor!.wordBrandWidth + this.editor!.canvasPadding,
      this.editor!.canvasPadding,
      this.selectedTextColor,
      this.editor!.selectedBranding?.name.get('textLines')!.length
    );

    if (fileCreationRequest.imageData.pngBase64Image) {
      this.preview = fileCreationRequest.imageData.pngBase64Image;
    } else if (fileCreationRequest.imageData.jpegBase64Image) {
      this.preview = fileCreationRequest.imageData.jpegBase64Image;
    }

    // start loader
    this.loading.emit(true);

    this.fileCreationService
      .sendFileCreationRequest(fileCreationRequest)
      .subscribe(
        (value) => {
          if (value.status === 'success') {
            localStorage.setItem(
              'logoBackgroundColor',
              this.selectedBackgroundColor!
            );
            localStorage.setItem('logoPreview', this.preview!);
            // remove loader
            this.loading.emit(false);
            this.router.navigateByUrl('/success');
          } else {
            // remove loader
            this.loading.emit(false);
            canvasExportService.restoreCanvasAfterExport();
          }
        },
        (error) => {
          // remove loader
          this.loading.emit(false);
          canvasExportService.restoreCanvasAfterExport();
        }
      );
  }

  get firstName() {
    return this.fileCreationForm.get('contactData')!.get('firstName')!;
  }
  get lastName() {
    return this.fileCreationForm.get('contactData')!.get('lastName')!;
  }
  get email() {
    return this.fileCreationForm.get('contactData')!.get('email')!;
  }
  get colorSpace() {
    return this.fileCreationForm.get('colorSpace')!;
  }
  get size() {
    return this.fileCreationForm.get('size')!;
  }
  get fileFormats() {
    return this.fileCreationForm.get('fileFormats')!;
  }
  get usage() {
    return this.fileCreationForm.get('usage')!;
  }
}
