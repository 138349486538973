<div class="color-picker">
  <nb-tabset>
    <nb-tab tabTitle="Anwendung auf hellem Hintergrund" class="light">
      <div class="form-row">
        <div class="col-auto" *ngFor="let color of lightColors">
          <div class="container-radio">
            <label for="light-color-{{ color }}" class="color-{{ color }}">
              <input
                type="radio"
                name="color"
                id="light-color-{{ color }}"
                value="white-{{ color }}"
                [formControl]="colorInput"
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
      <div *ngIf="colorInput.value" class="form-row">
        <button class="button inverted" (click)="sendGeneratePreviewEvent()">
          Farbe anpassen
        </button>
      </div>
    </nb-tab>
    <nb-tab tabTitle="Anwendung auf dunklem Hintergrund" class="dark">
      <div class="form-row">
        <div class="col-auto" *ngFor="let color of darkColors">
          <div class="container-radio">
            <label for="dark-color-{{ color }}" class="color-{{ color }}">
              <input
                type="radio"
                name="color"
                id="dark-color-{{ color }}"
                value="black-{{ color }}"
                [formControl]="colorInput"
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
      <div *ngIf="colorInput.value" class="form-row">
        <button class="button inverted" (click)="sendGeneratePreviewEvent()">
          Farbe anpassen
        </button>
      </div>
    </nb-tab>
  </nb-tabset>
</div>
