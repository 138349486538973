<section class="editor">
  <canvas id="logo-generator" width="500" height="500"></canvas>
  <div *ngIf="logoType === 'Bild-Marke'" class="row">
    <div class="logo-hints">
      <span class="icon icon-information"></span>
      <p class="small">
        Achtung! Das beste Ergebnis erhalten Sie, wenn das zusätzliche Logo
      </p>
      <ul>
        <li>einfarbig bzw. schwarz-weiß ist,</li>
        <li>freigestellt ist (ohne Hintergrund),</li>
        <li>im SVG-Format von Ihnen hochgeladen wird.</li>
        <li>
          Sollten Sie 3 oder mehr Textzeilen belegen, kann kein Logo ergänzt
          werden.
        </li>
      </ul>
      <p class="small">
        JPG- und PNG-Formate sollten mindestens in einer Auflösung von 300 dpi
        zur Verfügung gestellt werden.
      </p>
    </div>
  </div>
  <div class="form-row">
    <div class="col-auto">
      <button class="button random" (click)="randomizeLogo()">
        <span class="icon icon-random"></span>
        Zufällige Konfiguration
      </button>
    </div>
    <div class="col-auto">
      <div ngbDropdown>
        <button
          type="button"
          class="button"
          id="brandingButton"
          ngbDropdownToggle
        >
          <span class="icon icon-plus"></span>
          Logo kombinieren
        </button>
        <div ngbDropdownMenu aria-labelledby="brandingButton">
          <div
            ngbDropdownItem
            (click)="
              removeBranding();
              addBrandText();
              logoType = 'Wort-Bild-Marke';
              logoTypeForDropdown = 'Standard'
            "
            *ngIf="logoTypeForDropdown != 'Standard'"
          >
            Standard Logo
          </div>
          <div
            ngbDropdownItem
            *ngIf="logoTypeForDropdown != 'Intern'"
            (click)="logoTypeForDropdown = 'Intern'; addBranding('intern')"
          >
            eigenes Logo ergänzen
          </div>
          <div
            ngbDropdownItem
            *ngIf="logoTypeForDropdown != 'Extern'"
            (click)="logoTypeForDropdown = 'Extern'; addBranding('extern')"
          >
            externen Partner ergänzen
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<img
  style="display: none"
  src="../assets/img/pattern-green.svg"
  #backgroundImage
/>
<img
  style="display: none"
  src="../assets/img/pattern-red.svg"
  #backgroundErrorImage
/>
