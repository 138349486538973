import { Component, Input } from '@angular/core';
import { fabric } from 'fabric';
import { CanvasExportService } from '@app/lib/service/canvasExportService';

@Component({
  selector: 'app-logo-preview',
  templateUrl: './logo-preview.component.html',
  styleUrls: ['./logo-preview.component.scss'],
})
export class LogoPreviewComponent {
  @Input('canvasPadding') canvasPadding: number = 0;

  backgroundColor: string = 'white';

  canvas?: fabric.Canvas;

  base64image?: string;

  heightAdjusted: boolean = false;

  constructor() {}

  public generatePreview(
    textColor: string,
    backgroundColor: string,
    maxWidth: number
  ): string | undefined {
    fabric.Image.prototype.strokeWidth = 0;
    this.canvas!.enableRetinaScaling = true;
    if (!this.heightAdjusted) {
      let newHeight = this.canvas!.getHeight() + 2;
      this.canvas!.setHeight(newHeight);
      this.heightAdjusted = true;
    }
    const canvasExportService = new CanvasExportService(this.canvas!);
    if (
      canvasExportService.validateTextColor(textColor) &&
      CanvasExportService.validateBackgroundColor(backgroundColor)
    ) {
      const objects = this.canvas!.getObjects();
      canvasExportService.prepareCanvasForExport(objects);
      const visibleObjects =
        canvasExportService.getVisibleCanvasObjects(objects);
      canvasExportService.changeCanvasColors(
        visibleObjects,
        textColor,
        backgroundColor
      );

      // change canvas width by calculating widths text objects and add a border to canvas
      canvasExportService.calculateCiBorder(this.canvasPadding, maxWidth);

      this.base64image = this.canvas!.toDataURL({
        format: 'png',
        multiplier: 2,
      });
      canvasExportService.changeCanvasColors(visibleObjects, 'black', 'white');
      canvasExportService.restoreCanvasAfterExport();
    }
    return this.base64image;
  }
}
