import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit {
  @Output('colorSelected') colorSelected: EventEmitter<{
    textColor: string;
    backgroundColor: string;
  }> = new EventEmitter<{ textColor: string; backgroundColor: string }>();
  @Output('generatePreview') generatePreview: EventEmitter<any> =
    new EventEmitter<any>();

  lightColors: string[] = [
    'taintedBlue',
    'mintGreen',
    'darkBlue',
    'lightBlue',
    'petrolGreen',
    'green',
    'purple',
    'pink',
    'red',
    'orange',
    'black',
  ];
  darkColors: string[] = [
    'taintedBlue',
    'mintGreen',
    'lightBlue',
    'petrolGreen',
    'green',
    'pink',
    'red',
    'orange',
    'white',
  ];

  colorInput = new FormControl('');

  constructor() {}

  ngOnInit(): void {
    this.onColorChange();
  }

  private onColorChange(): void {
    this.colorInput.valueChanges.subscribe((val: string) => {
      const colors = val.split('-');
      this.colorSelected.emit({
        textColor: colors[1],
        backgroundColor: colors[0],
      });
    });
  }

  public sendGeneratePreviewEvent(): void {
    this.generatePreview.emit();
  }
}
