import {
  Component,
  EventEmitter,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FileCreationService } from '@app/lib/service/fileCreationService';

@Component({
  selector: 'app-image-upload-modal',
  templateUrl: './image-upload-modal.component.html',
  styleUrls: ['./image-upload-modal.component.scss'],
})
export class ImageUploadModalComponent {
  @Output('imageUpload') imageUpload: EventEmitter<any> =
    new EventEmitter<any>();

  @ViewChild('imageUploadModal') imageUploadModal?: TemplateRef<any>;

  modal?: NgbModalRef;

  fileName: string = 'Keine ausgewählt';

  constructor(
    private modalService: NgbModal,
    private fileCreationService: FileCreationService
  ) {}

  public getImageData(imageEvent: any) {
    this.fileName = this.truncate(imageEvent.target.files[0].name);
    const fileReader = new FileReader();
    fileReader.onload = (event: ProgressEvent<FileReader>) => {
      this.imageUpload.emit(event.target?.result);
      this.modal!.close();
      imageEvent.target!.value = '';
    };
    if (imageEvent.target.files[0].type.includes('image/')) {
      fileReader.readAsDataURL(imageEvent.target.files[0]);
    } else {
      this.fileCreationService
        .sendEPSConversionRequest(
          (imageEvent.target as HTMLInputElement).files![0]
        )
        .subscribe((result) => {
          this.imageUpload.emit(result.svgFile);
          this.modal!.close();
        });
    }
  }

  public open(): void {
    this.modal = this.modalService.open(this.imageUploadModal);
  }

  private truncate(text: string): string {
    if (text.length > 50) {
      if (text.charAt(50) === '.') {
        return text.substring(0, 50) + '..';
      }
      return text.substring(0, 50) + '...';
    } else {
      return text;
    }
  }
}
