import { Component, Input } from "@angular/core";

@Component({
  selector: 'app-theme-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  @Input("headerData") headerData = {
    headline: "Default",
    bodytext: "<p>Default.</p>",
  };
}
