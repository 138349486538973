<ng-template #imageUploadModal>
  <div class="modal-header">
    <h3 class="modal-title">Logo hochladen</h3>
    <button class="btn-close" aria-label="Close" (click)="modal!.close()"><span class="icon icon-close"></span></button>
  </div>
  <div class="modal-body">
    <form>
      <input type="file" accept="image/*" id="logo-upload" (change)="getImageData($event)">
      <label for="logo-upload">
        <span class="button">Datei auswählen</span>
        {{ fileName }}
      </label>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="button inverted" (click)="modal!.close()">Schließen</button>
  </div>
</ng-template>
