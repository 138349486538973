import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input('headline') headline: string = 'Headline';
  @Input('bodytext') bodytext: string =
    "<p>Bodytext</p><a class='button'>Test</a>";

  constructor(public router: Router) {}

  ngOnInit(): void {}
}
