import { Component, ComponentRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { GeneratorComponent } from './views/generator/generator.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Card Editor';

  headerData = {
    headline: 'Default',
    bodytext: '<p>Default.</p>',
  };

  loading = false;

  public handleHeaderData(componentRef: ComponentRef<any>) {
    this.headerData = (componentRef as any).headerData;

    if (componentRef instanceof GeneratorComponent) {
      const generatorComponent: GeneratorComponent = componentRef;
      generatorComponent.loading.subscribe((value) => {
        if (value !== undefined) {
          this.loading = value;
        }
      });
    }
  }
}
