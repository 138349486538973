<nb-layout>
  <nb-layout-column>
    <app-header
      [headline]="headerData.headline"
      [bodytext]="headerData.bodytext"
    ></app-header>
    <ng-content select="router-outlet"></ng-content>
    <app-footer></app-footer>
  </nb-layout-column>
</nb-layout>
