<div *ngIf="base64image" class="preview-wrapper bg-{{ backgroundColor }}">
  <div class="row">
    <div class="col-10 offset-1">
      <h3 *ngIf="backgroundColor === 'black'">
        Vorschau Ihres Logos auf dunklem Hintergrund
      </h3>
      <h3 *ngIf="backgroundColor === 'white'">
        Vorschau Ihres Logos auf hellem Hintergrund
      </h3>
      <hr />
      <img src="{{ base64image }}" alt="Logo Preview" />
    </div>
  </div>
</div>
