<div class="generator">
  <div class="container content">
    <div class="row">
      <div class="col-10 offset-1">
        <nb-stepper
          orientation="horizontal"
          [linear]="true"
          (stepChange)="handleStepChangeEvent($event)"
        >
          <nb-step label="Logo konfigurieren" [stepControl]="editor.logoValid">
            <h2>Logo konfigurieren</h2>
            <h6 *ngIf="editor.logoTypeForDropdown == 'Standard'">
              Standard Logo
            </h6>
            <h6 *ngIf="editor.logoTypeForDropdown == 'Intern'">
              Intern Co-Partner
            </h6>
            <h6 *ngIf="editor.logoTypeForDropdown == 'Extern'">
              Externe Co-Partner
            </h6>
            <app-editor
              #editor
              (brandingAdded)="updateHeaderData($event)"
            ></app-editor>
            <app-image-upload-modal
              (imageUpload)="editor.addLogo($event)"
            ></app-image-upload-modal>
            <button
              class="next"
              nbButton
              nbStepperNext
              [disabled]="!editor.logoValid.value"
            >
              Weiter
            </button>
          </nb-step>
          <nb-step label="Farbe wählen" [stepControl]="previewGenerated">
            <h2>Farbe wählen</h2>
            <app-color-picker
              (colorSelected)="updateSelectedColor($event)"
              (generatePreview)="handleGeneratePreviewEvent()"
            ></app-color-picker>
            <app-logo-preview [canvasPadding]="editor!.canvasPadding"></app-logo-preview>
            <button
              class="next"
              nbButton
              nbStepperNext
              [disabled]="!previewGenerated.value"
            >
              Weiter
            </button>
            <button class="prev" nbButton nbStepperPrevious>Zurück</button>
          </nb-step>
          <nb-step label="Logo finalisieren">
            <h2>Logoversand</h2>
            <div class="row">
              <div class="col-6">
                <p class="spacer-40">
                  Sind Sie fertig und zufrieden? Wenn ja, füllen Sie bitte das
                  folgende Formular aus. <br />
                  Im Anschluss bekommen Sie das Logo im gewünschten Format und
                  der gewünschten Größe per Email zugeschickt.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <form
                  #fileCreationFormElement
                  [formGroup]="fileCreationForm"
                  class="file-creation-form"
                  (submit)="handleFileCreationFormSubmit($event)"
                >
                  <div class="form-group">
                    <label
                      [ngClass]="{
                        'label-error':
                          formValidated && fileFormats.status === 'INVALID'
                      }"
                      >Farbraum</label
                    >
                    <nb-radio-group formControlName="colorSpace">
                      <nb-radio required value="digital"
                        >Anwendung in Digital (RGB)</nb-radio
                      >
                      <nb-radio required value="print"
                        >Anwendung in Print (CMYK)</nb-radio
                      >
                    </nb-radio-group>
                    <div
                      *ngIf="formValidated && colorSpace.status === 'INVALID'"
                      class="error-container"
                    >
                      Bitte Farbraum wählen
                      <span class="icon icon-error"></span>
                    </div>
                  </div>
                  <!-- <div class="form-group">
                    <label>Zielgröße des Logos</label>
                    <ng-select
                      placeholder="Bitte wählen"
                      formControlName="size"
                      [searchable]="false"
                      [clearable]="false"
                      required
                      class="disabled"
                      #sizeDropdown
                    >
                      <ng-option
                        *ngFor="
                          let option of sizeSelectOptions[colorSpace.value]
                        "
                        value="{{ option.width }}x{{ option.height }}"
                        >{{ option.width }}x{{ option.height }}
                      </ng-option>
                    </ng-select>
                    <div
                      *ngIf="formValidated && size.status === 'INVALID'"
                      class="error-container"
                    >
                      Bitte Größe wählen
                      <span class="icon icon-error"></span>
                    </div>
                  </div> -->
                  <div class="form-row">
                    <div class="col-12">
                      <label
                        [ngClass]="{
                          'label-error':
                            formValidated && fileFormats.status === 'INVALID'
                        }"
                        >Gewünschte Formate</label
                      >
                    </div>
                    <div class="col" formGroupName="fileFormats">
                      <nb-checkbox formControlName="jpg">JPG</nb-checkbox>
                      <nb-checkbox formControlName="png">PNG</nb-checkbox>
                      <nb-checkbox formControlName="eps">EPS</nb-checkbox>
                      <nb-checkbox formControlName="pdf">PDF</nb-checkbox>
                      <nb-checkbox formControlName="svg">SVG</nb-checkbox>
                    </div>
                    <div
                      *ngIf="formValidated && fileFormats.status === 'INVALID'"
                      class="col-12 error-container"
                    >
                      Bitte Format wählen
                      <span class="icon icon-error"></span>
                    </div>
                  </div>
                  <div class="form-group" formGroupName="contactData">
                    <h3>Ihre Kontaktdaten</h3>
                    <input
                      required
                      nbInput
                      fullWidth
                      type="text"
                      placeholder="Vorname"
                      formControlName="firstName"
                    />
                    <div
                      *ngIf="formValidated && firstName.status === 'INVALID'"
                      class="error-container"
                    >
                      Bitte Vorname angeben
                      <span class="icon icon-error"></span>
                    </div>
                    <input
                      required
                      nbInput
                      fullWidth
                      type="text"
                      placeholder="Nachname"
                      formControlName="lastName"
                    />
                    <div
                      *ngIf="formValidated && lastName.status === 'INVALID'"
                      class="error-container"
                    >
                      Bitte Nachname angeben
                      <span class="icon icon-error"></span>
                    </div>
                    <input
                      required
                      nbInput
                      fullWidth
                      type="text"
                      placeholder="E-Mail"
                      formControlName="email"
                    />
                    <div
                      *ngIf="formValidated && email.status === 'INVALID'"
                      class="error-container"
                    >
                      Bitte Email angeben
                      <span class="icon icon-error"></span>
                    </div>
                  </div>
                  <div class="form-group">
                    <h3>Einsatzzweck für das Logo</h3>
                    <!-- <label>Einsatz</label> -->
                    <!-- <ng-select
                      placeholder="Bitte wählen"
                      formControlName="usage"
                      [searchable]="false"
                      [clearable]="false"
                      required
                    >
                      <ng-option
                        *ngFor="let option of usageSelectOptions"
                        value="{{ option.value }}"
                        >{{ option.value }}
                      </ng-option>
                    </ng-select> -->
                    <textarea
                      required
                      nbInput
                      fullWidth
                      type="text"
                      placeholder="Einsatz"
                      formControlName="usage"
                      rows="4"
                    >
                    </textarea>
                    <div
                      *ngIf="formValidated && usage.status === 'INVALID'"
                      class="error-container"
                    >
                      Bitte Einsatzzweck wählen
                      <span class="icon icon-error"></span>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-6">
                <img *ngIf="preview" src="{{ preview }}" alt="Logo Preview" />
              </div>
            </div>
            <button
              class="next"
              (click)="fileCreationFormElement.requestSubmit()"
              [disabled]="
                formValidated && fileCreationForm.status === 'INVALID'
              "
            >
              Logo versenden
            </button>
            <button class="prev" nbButton nbStepperPrevious>Zurück</button>
          </nb-step>
        </nb-stepper>
      </div>
    </div>
  </div>
  <app-logo-usage-hints
    *ngIf="formStepShown"
    [backgroundColor]="'black'"
  ></app-logo-usage-hints>
</div>
